import React, { Component } from "react";
import Slider from "react-slick";
import "../common/index.scss";
// import { listShop } from "@/utils/user";
// import custom Components
import { putProductInfo } from '@/storage/PageParamers'
import ProductListing from "./common/product-listing";
import Breadcrumb from "../common/breadcrumb";
import {
  SideBar,
  SearchBar
} from 'antd-mobile'
import { listShopCate, listSpecProd } from '@/utils/user'
import './collection-no-sidebar.css'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";

let pageContext
class CollectionNoSideBar extends Component {
  constructor(props) {
    super(props);
    loadLocales('collection')
    this.state = {
      layoutColumns: 3,
      listShop: [],
      refresh: true,
      tabskeys: "first",
      listShopCate: []
      , selectedKeys: null
    };
    pageContext = this

  }

  componentDidMount() {
    pageContext.listShopCate();
  }
  listShopCate() {
    listShopCate().then(res => {
      //接收后台返回的数据
      if (res && res.length > 0) {
        // putProductInfo(res);
        pageContext.setState({ listShopCate: res });
      }
    });
  }

  LayoutViewClicked(colums) {
    pageContext.setState({
      layoutColumns: colums
    });
  }
  onRefresh = () => {
    pageContext.setState({ refresh: !pageContext.state.refresh });
  };

  onSortBy = sort => {
    // 通过子组件的实例调用组组件中的方法
    pageContext.childRef.listShop();
    pageContext.setState({
      sort: sort,
      refresh: !pageContext.state.refresh
    });
  };

  handleChildEvent = ref => {
    // 将子组件的实例存到 pageContext.childRef 中, 这样整个父组件就能拿到
    pageContext.childRef = ref;
  };
  onChange(keys, event) {
    const { listShopCate } = pageContext.state
    let newKeyId = keys
    for (const key in listShopCate) {
      if (listShopCate[key].key == keys && keys != "first") {
        newKeyId = key
      }
    }
    pageContext.setState({ tabskeys: newKeyId })
  };

  handleChange = (value) => {
    pageContext.childRef.listShop({ sortBy: value });
  };

  onSearch = (value) => {
    pageContext.childRef.listShop({ onSearch: value });
  }

  onTreeSelect = (newSelectedKeys) => {
    const { selectedKeys, listShopCate } = this.state
    if (newSelectedKeys !== selectedKeys) {
      let newItem = listShopCate.filter(item => item.key === newSelectedKeys)
      let cateCode
      if (newItem.length > 0) {
        cateCode = newItem[0].cateCode
      } else {
        cateCode = false
      }

      this.setState({ selectedKeys, cateCode }, () => { pageContext.childRef.onTreeSelectCateCode(cateCode) });
    }

  }

  listSpecProd() {
    let params = {};
    listSpecProd(params).then(res => {
      if (res) {
        pageContext.setState({ listSpecProd: res.records }, () => { pageContext.setState({ refresh: !pageContext.state.refresh }) });
        putProductInfo(res.records);
      }
    });
  }

  render() {
    const { tabskeys, listShopCate } = pageContext.state

    const items = [
      <SideBar.Item title={intl.get("allProducts")} key="first">

      </SideBar.Item>
    ]

    listShopCate.map(item => items.push(
      < SideBar.Item title={item.title} key={item.key} >
      </SideBar.Item >
    ))
    return (
      <div style={{ height: "100%" }}>

        <SearchBar style={{
          '--border-radius': '100px',
          '--background': '#ffffff',
          '--height': '40px',
          '--padding-left': '12px',
        }} onClear={pageContext.onClear} onSearch={pageContext.onSearch} placeholder={intl.get("productKeywords")} />

        <div className="container-collection">
          <div className="side">
            <SideBar
            // activeKey={activeKey} onChange={setActiveKey}
            >
              {items}
            </SideBar>
          </div>
          <div className="main">
            <div
              className="content-collection"
            // className={classNames(
            //   styles.content,
            //   activeKey === 'key1' && styles.active
            // )}
            >
              <ProductListing
                sort={
                  !pageContext.state.sort ? "asc" : pageContext.state.sort
                }
                onChildEvent={pageContext.handleChildEvent}
                listShopCate={listShopCate}
                tabskeys={tabskeys}
                onRefresh={pageContext.onRefresh}
                colSize={pageContext.state.layoutColumns}
              />
            </div>

          </div>
        </div>
        {/* <Tabs activeKey='first' style={{ '--active-line-color': "red", '--active-title-color': 'red' }} defaultActiveKey='1'>
          {items}
        </Tabs> */}

      </div>
    );
  }
}

export default CollectionNoSideBar;
