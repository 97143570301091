import React, { Component } from "react";
import { Link } from "react-router-dom";

import { SlideUpDown } from "../../../services/script";
import LogoImage from "../headers/common/logo";
import "./footer-one.css";
import "@/components/app.scss"
class FooterOne extends Component {
  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function (elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }

  render() {
    const newDate = new Date().getFullYear();
    return (
      <footer className="footer-light footerPositions">
        <span className="footerStyle">
          Copyright Hands Information Technology (Zhuhai) Ltd. ® {newDate}. All
          Rights Reserved. 帮手信息技术（珠海）有限公司
        </span>
      </footer>
    );
  }
}

export default FooterOne;
