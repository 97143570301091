import {
  PURCHASE_NOW_PRODUCT,
  PURCHASE_NOW_PRODUCT_DEL
} from "../constants/ActionTypes";


export default function purchaseNowReducer(state = {
  purchaseNowProduct: false
}, action) {
  switch (action.type) {
    case PURCHASE_NOW_PRODUCT:
      return { purchaseNowProduct: { ...action.value, qty: action.qty } }
    case PURCHASE_NOW_PRODUCT_DEL:
      return { purchaseNowProduct: false }
    default:
  }
  return state;
}
