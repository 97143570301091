
import React, { useEffect, useState } from 'react';
import { Modal, Radio, Divider, Form, Input, Button, InputNumber } from 'antd';
import { addDdress, setDefault } from '@/utils/user.js'

import { getOwnerKeyId } from '@/storage/SessionParamers'
import intl from "react-intl-universal";

export default function deliveryAddressModal(props) {
  const [form] = Form.useForm();
  const [useAddress, setUseAddress] = useState(false);
  const [defaultDdressKeyId, setDefaultDdressKeyId] = useState(!props.defaultDdress ? "" : props.defaultDdress.keyId)

  useEffect(() => {
    if (props.ddressList.length > 0) {
      props.ddressList.forEach((item, index) => { if (item.isDefault == 1) setUseAddress(index + 1) })
    }
  }, [form]);

  const onCheckbox = (checkedValue) => {
    if (checkedValue.target.checked === true) {
      setUseAddress(checkedValue.target.value)
    }
  }

  const onFinish = () => {
    if (useAddress === props.ddressList.length + 1 || !useAddress) {
      form.validateFields().then((values) => {
        let params = values
        params.ownerKeyId = getOwnerKeyId();
        // params.userKeyId = getOwnerKeyId();
        params.isDefault = defaultDdressKeyId === "newDefaultAdress" ? "1" : "0"
        addDdress(params).then(res => {
          if (res && res.responseCode == "00") {
            props.onUseNewAddress()
            props.onUseAnotherAddressHandleCancel()
          }
        })
      })
    } else {
      // selectAndUseDdress
      props.onUseAnotherAddressHandleCancel(useAddress - 1)
    }

  };

  const onFinishFailed = (errorInfo) => {
  };
  const onClickNewAddress = () => {
    setUseAddress(props.ddressList.length + 1)
  };

  const onNewDefaultAddress = (value) => {
    if (value !== "newDefaultAdress") {
      const param = { addressKeyId: value }
      setDefault(param).then(res => {
        if (res && res.responseCode === "00") {
          setDefaultDdressKeyId(value)
        }
      })
    } else {
      setDefaultDdressKeyId(value)

    }

  }
  return (
    <Modal
      open={props.openState}
      title={intl.get("selectShippingAddress")}
      width={800}
      // onOk={handleOk}
      onCancel={props.onUseAnotherAddressHandleCancel}
      footer={[
        // <Button key="back" onClick={handleCancel}>
        //   Return
        // </Button>,
      ]}
    >
      <Divider />
      <Radio.Group style={{ width: "100%" }} onChange={onCheckbox}
        value={useAddress}
      >

        {props.ddressList.map((item, index) =>
          <Radio value={index + 1} className={useAddress == (index + 1) ? "radio_frame" : "radio_frame_none"} >
            <div className='form_deliveryAddressList_title'>
              <div className='font-size-20'>
                {/* <Checkbox onChange={this.props.onCheckbox} checked={item.isDefault}>Checkbox</Checkbox> */}
                <div className="select_delivery_address_sty" >
                  <div>{item.recieveName}</div>
                  <div className='margin-left-20'>{item.phone}</div>
                  {defaultDdressKeyId == item.keyId ? <div className='default_address_sty margin-left-20'>{intl.get("default")}</div> : ""}
                </div>
                <div className="form_deliveryAddress_sty">{item.recieveAddress}</div>
              </div>
              {defaultDdressKeyId == item.keyId ? "" : <div className='color_blue' onClick={() => onNewDefaultAddress(item.keyId)}>{intl.get("setAsDefault")}</div>}
            </div>
          </Radio>
        )
        }
        <Form name="basic"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          onClick={onClickNewAddress}
        >
          <div className={useAddress == props.ddressList.length + 1 ? "radio_frame" : "radio_frame_none"}>
            <div className='form_deliveryAddress_title'>
              <h4 >{intl.get("useNewAddress")} {defaultDdressKeyId == "newDefaultAdress" ? <div className='default_address_sty margin-left-20'>{intl.get("default")}</div> : ""}</h4>
              {defaultDdressKeyId == "newDefaultAdress" ? "" : <div onClick={() => onNewDefaultAddress("newDefaultAdress")}>{intl.get("setAsDefault")}</div>}
            </div>
            <div className='form_deliveryAddress' >
              <Form.Item label={intl.get("addressee")} name="recieveName"
                className='form_item_deliveryAddress'

                rules={[
                  {
                    required: true,
                    message: intl.get("enterRecipientName"),
                  },
                ]}
              >
                <Input size="large" placeholder={intl.get("enterRecipientName")} />
              </Form.Item>
              <Form.Item label={intl.get("telephoneNumber")} name="phone"
                className='form_item_deliveryAddress'

                rules={[
                  {
                    required: true,
                    message: intl.get("enterContactNumber"),
                  },
                ]}
              >
                <InputNumber placeholder={intl.get("enterContactNumber")} size="large" controls={false} style={{ width: "100%" }} />
              </Form.Item>
            </div>
            <div className='form_deliveryAddress' >
              <Form.Item label={intl.get("landline")} name="tel"
                className='form_item_deliveryAddress'
              >
                <InputNumber placeholder={intl.get("enterLandline")} size="large" controls={false} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label={intl.get("fax")} name="fax"
                className='form_item_deliveryAddress'
              >
                <InputNumber placeholder={intl.get("enterFax")} size="large" controls={false} style={{ width: "100%" }} />
              </Form.Item>
            </div>
            <div  >
              <Form.Item label={intl.get("deliveryAddress")} name="recieveAddress"
                className='form_item_deliveryAddress_80'
                rules={[
                  {
                    required: true,
                    message: intl.get("enterShippingAddress"),
                  },
                ]}
              >
                <Input size="large" placeholder={intl.get("enterShippingAddress")} />
              </Form.Item>
            </div>
          </div>
        </Form>

      </Radio.Group >
      <Button onClick={onFinish} type="primary" ghost htmlType="submit">
        {intl.get("completeSelection")}
      </Button>
    </Modal >
  )
}
