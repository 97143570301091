import intl from "react-intl-universal";
import { browserLanguage } from "@/utils/BrowserLanguage";
export function loadLocales(path) {
  const commonLocales = {
    en_US: require(`@/locales/en-US.json`),
    zh_CN: require(`@/locales/zh-CN.json`),
    zh_TW: require(`@/locales/zh-TW.json`)
  };

  const currentPageLocales = {
    en_US: require(`@/components/${path}/locales/en-US.json`),
    zh_CN: require(`@/components/${path}/locales/zh-CN.json`),
    zh_TW: require(`@/components/${path}/locales/zh-TW.json`)
  };

  const locales = {
    "en-US": Object.assign(commonLocales.en_US, currentPageLocales.en_US),
    "zh-CN": Object.assign(commonLocales.zh_CN, currentPageLocales.zh_CN),
    "zh-TW": Object.assign(commonLocales.zh_TW, currentPageLocales.zh_TW)
  };

  let lang = browserLanguage(); //常规浏览器语言和IE浏览器
  // if (lang == "en-US") {

  // } else if (getbocJumpPage()) {
  //   lang = "zh-TW"
  // }
  //init method will load CLDR locale data according to currentLocale
  //react-intl-universal is singleton, so you should init it only once in your app
  intl
    .init({
      currentLocale: lang, // TODO: determine locale here
      locales
    })
    .then(() => {
      // TODO
    });
}
