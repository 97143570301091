import React, { Component } from 'react'
import { CheckCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import ProductRecommendation from '@/components/register/productRecommendation.js'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import {
  putJumpLoginPage
} from '@/storage/SessionParamers'
export default class passwordResetSuccess extends Component {
  constructor(props) {
    super(props);
    loadLocales('forgottenPassword')
  }
  newest = ref => {
    this.newestRef = ref;
  };
  offers = ref => {
    this.offersRef = ref;
  };


  render() {
    return (
      <div className='registration_successful'>
        <div className='registration_successful_scope'>
          <div className='registration_successful_icon'>
            <CheckCircleOutlined />
            <div>{intl.get("RetrievePasswordSuccessfully")}</div>
          </div>
          <div className='registration_successful_tips'>
            {intl.get("YouCanGoTo")} “<span style={{ color: "#2e3092" }}> {intl.get("SignIn")}</span>” {intl.get("CompleteYourLoginAndStartShopping")}
          </div>
          <div className='registration_successful_login' onClick={() => { putJumpLoginPage(window.location.pathname); this.props.history.push("/pages/login") }}>
            {intl.get("SignIn")}
          </div>
        </div>
        <div className='product_recommendation'>
          <div >

            <ProductRecommendation
              type={"1"}
              history={this.props.history} recommendation={this.offers}></ProductRecommendation>
          </div>
          <div >

            <ProductRecommendation type={"2"} history={this.props.history} recommendation={this.newest}></ProductRecommendation>
          </div>
        </div>
      </div>
    )
  }
}
