import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./breadcrumb.css"
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
loadLocales('common')
class Breadcrumb extends Component {

  render() {
    const { title, parent } = this.props;
    return (
      <div className="catalogue_sty">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`${process.env.PUBLIC_URL}`}>{intl.get("homePage")}</Link>
                </li>
                {parent ? (
                  <li className="breadcrumb-item" aria-current="page">
                    {parent}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  {title}
                </li>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumb;
