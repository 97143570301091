import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { getLoginInfo } from '@/storage/LocalParamers'
import { Grid, Image, Card, Badge } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { putSelectGoods } from '@/storage/SessionParamers'
const img_noPhoto = require("@/assets/images/portfolio/img_noPhoto.png")
const errorPhoto = require('@/images/errorPhoto.png')
class ProductsList extends Component {
  constructor(props) {
    super(props);
    loadLocales('layouts')
    this.state = { listAnMouth: props.listAnMouth, displayType: props.displayType };
  }
  render() {
    const { displayType, listAnMouth } = this.props
    return (
      <Card
        extra={<div > {intl.get("showAll")} <RightOutline /></div>}
        onHeaderClick={() => this.props.history.push(`${process.env.PUBLIC_URL}/no-sidebar/collection`)}
        title={
          <Badge content={listAnMouth.length}>
            <div className='fontSize-20'>{displayType == "1" ? intl.get("specialOffer") : intl.get("newProducts")}</div>
          </Badge>}>
        <Grid columns={3} >
          {listAnMouth.map(item =>
            <Grid.Item onClick={() => {
              putSelectGoods(item.goodsCode);
              this.props.history.push(`${process.env.PUBLIC_URL}/no-sidebar/product/${item.keyId}`)
            }} className="Card-Image">
              <Image src={item.mainPicture} width={100} height={100} fit='contain'
                onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }} />
              <div>{item.goodsName}</div>
              {getLoginInfo() ? <div className='productList_item_price_sty'>$ {displayType == "1" ? item.specialPrice : item.price}</div> : ""}
            </Grid.Item>
          )}
        </Grid>
      </Card >

    );
  }
}

export default ProductsList;