import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import "./product-list-item.scss";
import { getToken } from "@/storage/LocalParamers";
import intl from "react-intl-universal";
import { Image } from 'antd-mobile'
import { putSelectGoods, putJumpLoginPage } from '@/storage/SessionParamers'
const errorPhoto = require('@/images/errorPhoto.png')
class ProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock: "InStock",
      quantity: 1,
      image: "",
      modal: false,
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = e => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  onOpenConfirmModal = () => {
    this.setState({ modal: true });
  };

  onCloseConfirmModal = () => {
    this.setState({ modal: false });
  };
  onAddToCart = () => {
    if (!getToken()) {
      this.onOpenConfirmModal();
    } else {
      this.props.onAddToCartClicked(this.state.quantity);
    }
  };

  windowOpen = (product) => {
    putSelectGoods(product.goodsCode);
    window.open(`${process.env.PUBLIC_URL}/no-sidebar/product/${product.keyId}`, '_blank')
  }
  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      onAddToWishlistClicked,
      onAddToCompareClicked
    } = this.props;
    const { open, modal } = this.state;

    let RatingStars = [];
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i}></i>);
    }
    return (
      <div className="product_margin_mobile">
        <div className="img-wrapper">
          <div className="front">
            <Link
              onClick={() => this.windowOpen(product)}
            >
              <Image
                src={!product.mainPicture ? this.state.image : product.mainPicture}
                width={64}
                height={64}
                fit='cover'
                style={{ borderRadius: 4 }}
                onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}

              />

            </Link>
          </div>
          {product.specialStatus === 1 ? <div className='product-list-item-red'>{intl.get('discount')}</div> : ""}

        </div>
        <div className="product_detail_sty">
          <div className="rating">{RatingStars}</div>
          <Link
            onClick={() => this.windowOpen(product)}
          >
            <h6>{product.goodsName}</h6>
          </Link>
          {getToken() ? <h4>
            $
            {product.price}
            {/* <del>
                <span className="money">
                  {symbol}
                  {product.price}
                </span>
              </del> */}
          </h4> : ""}

          {product.variants ? (
            <ul className="color-variant">
              {product.variants.map((vari, i) => {
                return (
                  <li
                    className={vari.color}
                    key={i}
                    title={vari.color}
                    onClick={() => this.onClickHandle(vari.images)}
                  ></li>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </div>
        <Modal open={open} onClose={this.onCloseModal} center>
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6  col-xs-12">
                    <div className="quick-view-img">
                      <img
                        src={
                          !product.mainPicture
                            ? this.state.image
                            : product.mainPicture
                          //   !product.variants
                          //     ? ""
                          //     : product.variants
                          //     ? this.state.image
                          //       ? this.state.image
                          //       : product.variants[0].images
                          //     : product.pictures[0]
                        }
                        onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
                        alt=""
                        className="product_img_size img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 rtl-text">
                    <div className="product-right">
                      <h2> {product.name} </h2>
                      <h3>
                        {symbol}
                        {product.price -
                          (product.price * product.discount) / 100}
                        <del>
                          <span className="money">
                            {symbol}
                            {product.price}
                          </span>
                        </del>
                      </h3>
                      {product.variants ? (
                        <ul className="color-variant">
                          {product.variants.map((vari, i) => (
                            <li
                              className={vari.color}
                              key={i}
                              title={vari.color}
                              onClick={() => this.onClickHandle(vari.images)}
                            ></li>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                      <div className="border-product">
                        <h6 className="product-title">product details</h6>
                        <p>{product.shortDetails}</p>
                      </div>
                      <div className="product-description border-product">
                        {product.size ? (
                          <div className="size-box">
                            <ul>
                              {product.size.map((size, i) => {
                                return (
                                  <li key={i}>
                                    <a href="#">{size}</a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                        <h6 className="product-title">quantity</h6>
                        <div className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-left-minus"
                                onClick={this.minusQty}
                                data-type="minus"
                                data-field=""
                              >
                                <i className="fa fa-angle-left"></i>
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.quantity}
                              onChange={this.changeQty}
                              className="form-control input-number"
                            />
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-right-plus"
                                onClick={this.plusQty}
                                data-type="plus"
                                data-field=""
                              >
                                <i className="fa fa-angle-right"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="product-buttons">
                        <button
                          className="btn btn-solid"
                          onClick={() =>
                            onAddToCartClicked(this.state.quantity)
                          }
                        >
                          add to cart
                        </button>
                        <Link
                          onClick={() =>
                            putSelectGoods(product.goodsCode)
                          }
                          to={`${process.env.PUBLIC_URL}/no-sidebar/product/${product.id}`}
                          className="btn btn-solid"
                        >
                          view detail
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal >

        <Modal
          // ref={modalRef}
          open={modal}
          center={true}
          onClose={this.onCloseConfirmModal}
          classNames={{
            modal: "customModal"
          }}
        // initialFocusRef={modalRef}
        >
          <div className="confirmModal">
            <h3>{intl.get("loginrequiredtoaddtocart")}</h3>
            <button
              className="newBtn btn btn-secondary"
              onClick={this.onCloseConfirmModal}
            >
              {intl.get("cancel")}
            </button>
            <Link onClick={() => { putJumpLoginPage(window.location.pathname) }}
              to="/pages/login">
              <button
                className="newBtn btn btn-solid"
              // onClick={() => this.onClickLoginFn()}
              >
                {intl.get("recognise")}
              </button>
            </Link>
          </div>
        </Modal>
      </div >
    );
  }
}

export default ProductListItem;
