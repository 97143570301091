import React, { Component } from "react";
import "./details-price.css";
import { Image } from 'antd';
const errorPhoto = require('@/images/errorPhoto.png')
export default class ImageZoom extends Component {
  render() {
    const { image } = this.props;

    return (
      <Image
        onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }} src={`${image}`}
        style={{ width: "380px", height: "380px" }}
      />

    );
  }
}
