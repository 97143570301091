/*
   本地数据，关闭浏览器后依然存在
*/
export function putRememberMe(rememberMe) {
  window.localStorage.setItem('rememberMe', rememberMe);
}

export function getRememberMe() {
  return window.localStorage.getItem('rememberMe', 'false');
}

export function putUserName(userName) {
  window.localStorage.setItem('userName', userName);
}

export function removeUserName() {
  window.localStorage.removeItem("userName");
}

export function getUserName() {
  return window.localStorage.getItem('userName');
}

function randomStr(len) {
  len = len || 1;
  var $chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var maxPos = $chars.length;
  var pwd = "";
  for (var i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
export function getTempToken() {
  var token = window.localStorage.getItem("tempToken");
  if (token === undefined || token === "" || token === null) {
    token = "A@GH5" + randomStr(23) + ":5.1.1";
    putTempToken(token);
  }
  return token;
}

export function putTempToken(token) {
  window.localStorage.setItem("tempToken", token);
}


export function getToken() {
  let token = window.localStorage.getItem("token");
  return token;
}

export function putToken(token) {
  window.localStorage.setItem("token", token);
}

export function removeToken(token) {
  window.localStorage.removeItem("token");
}


export function getLoginInfo() {
  const token = getToken();
  if (token && token != "") {
    return token
  } else {
    return false
  }
}


export function getCurCode() {
  let curCode = window.localStorage.getItem("curCode");
  return "$";

  // if (curCode) {
  //   return JSON.parse(curCode);
  // } else {
  // }
}

export function putCurCode(curCode) {
  window.localStorage.setItem("curCode", JSON.stringify(curCode));
}