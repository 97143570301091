import React, { Component } from 'react'
import {
  getProductInfo, getLatestProducts,
  getSpecialOffer
} from "@/storage/PageParamers";
import { toast } from 'react-toastify';
import { putSelectGoods } from '@/storage/SessionParamers'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
const errorPhoto = require('@/images/errorPhoto.png')
export default class productRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: "86",
      page: 1,
      productList: [],
      totalPages: 1
    };
  }

  componentDidMount() {

    const sidebarPage = !this.props.sidebarPage ? 3 : 5;
    if (this.props.recommendation !== undefined) {
      this.props.recommendation(this);
    }
    const { type } = this.props;
    const list = type === "1" ? getSpecialOffer() : getLatestProducts();
    let productList = []
    let page = 1
    let nub = 0
    for (const key in list) {
      if (nub < (sidebarPage * page)) {
        productList.push(key)
        nub++
      }
    }
    if (list && list.length > 0) {
      this.setState({ totalPages: Math.ceil(list.length / 3) });
    }
    this.setState({ productList, });
  }

  nextPage() {
    const { totalPages, page } = this.state
    let newPage = page
    if (totalPages > newPage) {
      this.setState({ page: newPage + 1 }, () => this.refreshProductList());
    } else {
      this.setState({ page: 1 }, () => this.refreshProductList());
    }

  }
  previousPage() {
    const { totalPages, page } = this.state
    let newPage = page
    if (newPage == 1) {
      this.setState({ page: totalPages }, () => this.refreshProductList());
    } else {
      this.setState({ page: newPage - 1 }, () => this.refreshProductList());
    }
  }
  refreshProductList() {
    const { type } = this.props;
    const sidebarPage = 3;
    const list = type === "1" ? getSpecialOffer() : getLatestProducts();
    const { productList, page } = this.state
    let newProductList = []
    let nub = 0
    for (const key in list) {
      if (nub < (sidebarPage * page)) {
        newProductList.push(key)
        nub++
      }
    }
    newProductList = newProductList.filter(item1 => !productList.some(item2 => item2 === item1))
    this.setState({ productList: newProductList });
  }
  onGoodsJump(item) {
    let locationHref = window.location.href;
    if (!locationHref.includes(item.keyId)) {
      putSelectGoods(item.goodsCode)
      this.props.history.push(`${process.env.PUBLIC_URL}/no-sidebar/product/${item.keyId}`)
    }
  }

  render() {
    const { type } = this.props
    const { productList } = this.state;
    const list = type === "1" ? getSpecialOffer() : getLatestProducts();

    return (
      <div >
        <div className='product_recommendation_title'>
          {type === "1" ? intl.get("discountedProductRecommendations") : intl.get("newProductRecommendations")}
          <div>
            <LeftOutlined
              className={list && list.length > 3 ? "" : "outlined_style"}
              style={{ marginRight: "10px", cursor: list && list.length > 3 ? "" : "not-allowed" }}
              onClick={() => list && list.length > 3 ? this.previousPage() : ""} />
            <RightOutlined
              className={list && list.length > 3 ? "" : "outlined_style"}
              style={{ marginRight: "10px", cursor: list && list.length > 3 ? "" : "not-allowed" }}
              onClick={() => list && list.length > 3 ? this.nextPage() : ""} />
          </div>
        </div>
        <div className='product_recommendation_list'>
          {list && list.length > 0 ? list.map((item, index) =>
            productList.indexOf(index.toString()) != "-1" ?
              <div onClick={() => this.onGoodsJump(item)} key={index} className='product_recommendation_info'>
                <div className='product_recommendation_img'>
                  <img onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }} src={item.mainPicture} alt='' />
                </div>
                <div className='product_recommendation_name'>
                  <div>{item.goodsName}</div>
                  <div>{item.specialPrice}</div>
                  <div></div>
                </div>

              </div> : ""
          ) : ""
          }

        </div>
      </div>

    )
  }
}
