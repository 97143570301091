import React, { Component } from "react";
import { toast } from "react-toastify";

import Breadcrumb from "../common/breadcrumb";
import "./forgottenPasswordPage.css";
import { smsSend, resetPassword } from "@/utils/user";
import ForgottenPassword from '@/components/forgottenPassword/forgottenPassword'
import PasswordResetSuccess from '@/components/forgottenPassword/passwordResetSuccess'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";

let pageContext
class Register extends Component {

  constructor(props) {
    super(props);
    loadLocales('forgottenPassword')
    this.state = {
      yztime: 180, areaCode: "853", fname: "", lname: "",
      resetPasswordSuccessful: false,
      mobilePhoneNumber: false,
      loading: false
    };
    pageContext = this
    this.formData = React.createRef()
  }

  smsSend = () => {
    const { areaCode, mobilePhoneNumber } = this.state
    if (!mobilePhoneNumber) {
      toast.error(intl.get("PleaseProvideYourPhoneNumber"));
      return
    }
    let params = {
      phone: areaCode + "-" + mobilePhoneNumber
    };
    smsSend(params).then(data => {
      if (data.responseCode == "00") {
        pageContext.setState({ data: data.data, loading: true });
        pageContext.count();
      }
    })
  }
  handleOnOK() {
    const { loginPassword, confirmPassword } = pageContext.state;

    if (loginPassword != confirmPassword) {
      toast.error(intl.get("PleaseRe-enter"));
      return;
    }
    pageContext.shopUserRegister();
  }

  shopUserRegister() {
    const {
      loginPassword,
      confirmPassword,
      mobilePhoneNumber,
      areaCode,
      verificationCode,
      fname,
      lname
    } = pageContext.state;
    let params = {
      phoneNumber: areaCode + "-" + mobilePhoneNumber,
      password: confirmPassword,
      verificationCode: verificationCode
    };
    resetPassword(params).then(data => {
      if (data.responseCode == "00") {
        pageContext.setState({ resetPasswordSuccessful: true });
        // pageContext.context.router.history.push("#");
        // toast.success(data.responseMessage);
      } else {
        toast.error(data.responseMessage);
      }

    })

  }

  onInputPhoneChange(e) {
    pageContext.setState({ mobilePhoneNumber: e.target.value });
  }
  onInputCaptchaChange(e) {
    pageContext.setState({ verificationCode: e.target.value });
  }

  onSelectCountryCode(e) {
    pageContext.setState({ areaCode: e });
  }

  onInputFnameChange(e) {
    pageContext.setState({ fname: e.target.value });
  }

  onInputLnameChange(e) {
    pageContext.setState({ lname: e.target.value });
  }

  onInputLoginPasswordChange(e) {
    pageContext.setState({ loginPassword: e.target.value });
  }

  onInputConfirmPasswordChange(e) {
    pageContext.setState({ confirmPassword: e.target.value });
  }

  count() {
    let { yztime } = pageContext.state;
    let siv = setInterval(() => {
      pageContext.setState({ yztime: yztime-- }, () => {
        if (yztime <= -1) {
          clearInterval(siv); //倒计时( setInterval() 函数会每秒执行一次函数)，用 clearInterval() 来停止执行:
          pageContext.setState({ loading: false, yztime: 180 });
        }
      });
    }, 1000);
  }
  render() {
    const {
      loading,
      yztime,
      mobilePhoneNumber,
      verificationCode,
      lname,
      fname,
      areaCode,
      resetPasswordSuccessful
    } = this.state;
    return (
      <div>
        <Breadcrumb title={intl.get("registrations")} />
        <div style={{ marginTop: "20px" }} className="container">
          {!resetPasswordSuccessful ? <ForgottenPassword
            fname={fname}
            onSelectCountryCode={this.onSelectCountryCode}
            onInputLoginPasswordChange={this.onInputLoginPasswordChange}
            onInputFnameChange={this.onInputFnameChange}
            mobilePhoneNumber={mobilePhoneNumber}
            onInputPhoneChange={this.onInputPhoneChange}
            onInputConfirmPasswordChange={this.onInputConfirmPasswordChange}
            verificationCode={verificationCode}
            onInputCaptchaChange={this.onInputCaptchaChange}
            yztime={yztime}
            loading={loading}
            smsSend={this.smsSend}
            handleOnOK={this.handleOnOK}
          ></ForgottenPassword> :
            <PasswordResetSuccess history={this.props.history}></PasswordResetSuccess>
          }
        </div>
        {/*Regsiter section*/}
        <section className="register-page section-b-space">
          <div className="container container1">
            <div className="row">

            </div>
          </div >
        </section >
      </div >
    );
  }
}

export default Register;
