import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";

class DetailsTopTabs extends Component {
  constructor(props) {
    super(props);
    loadLocales('products')

  }
  render() {
    const { selectDetail, inventoryData } = this.props;

    return (
      <section className="tab-product m-0">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <Tabs className="tab-content nav-material">
              <TabList className="nav nav-tabs nav-material">
                <Tab className="nav-item">
                  <span className="nav-link active">
                    <i className="icofont icofont-ui-home"></i>{intl.get("ProductParameters")}
                  </span>
                  <div className="material-border"></div>
                </Tab>
                <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-man-in-glasses"></i>{intl.get("ProductDescription")}
                  </span>
                  <div className="material-border"></div>
                </Tab>
              </TabList>
              <TabPanel className="tab-pane fade mt-4 show active">
                <table className="table table-striped mb-0">
                  <tbody>
                    {inventoryData.shopGoodsParamList
                      ? inventoryData.shopGoodsParamList.map(item => (
                        <tr>
                          <th>{item.paramName}:</th>
                          <td>{item.paramValue}</td>
                        </tr>
                      ))
                      : ""}
                  </tbody>
                </table>
              </TabPanel>
              <TabPanel>
                <p
                  className="mt-4 p-0"
                  dangerouslySetInnerHTML={{
                    __html: selectDetail.introduce
                  }}
                ></p>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

export default DetailsTopTabs;
