import React, { Component } from 'react'
import { getDdressList, addDdress, setDefault, deleteDdress } from '@/utils/user'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Button, Input, InputNumber } from 'antd';
import { getOwnerKeyId } from '@/storage/SessionParamers'
import { toast } from "react-toastify";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
export default class AddressController extends Component {
  constructor(props) {
    super(props);
    loadLocales('PersonalCentre')

    this.state = {
      ddressList: []
    }
    this.formRef = React.createRef();
  }
  componentDidMount() {
    this.getAddressList()
  }

  getAddressList() {
    getDdressList().then(res => {
      if (res && res.responseCode == "00") {
        this.setState({ ddressList: res.data });
      }
    })
  }
  onDeleteAddress = (item) => {
    const param = {
      rowVersion: "1",
      keyId: item.keyId
    }
    deleteDdress(param).then(res => {
    })
  }
  onFinish = () => {
    this.formRef.current.validateFields().then((values) => {
      let params = values
      params.ownerKeyId = getOwnerKeyId();
      // params.userKeyId = getOwnerKeyId();
      params.isDefault = this.state.ddressList.length == 0 ? "1" : "0"
      addDdress(params).then(res => {
        if (res && res.responseCode == "00") {
          toast.success(intl.get("ModifiedSuccessfully"));
          this.getAddressList()
        }
      })
    })
  }


  onFinishFailed = (errorInfo) => {
  };

  onClickNewAddress = () => {
    // setUseAddress(props.ddressList.length + 1)
  };

  onNewDefaultAddress = (value) => {
    const param = { addressKeyId: value }
    setDefault(param).then(res => {
      if (res && res.responseCode === "00") {
        toast.success(intl.get("ModifiedSuccessfully"));
        this.getAddressList()
      }
    })

  }

  render() {
    const { ddressList } = this.state
    let showDdressList = false;
    if (ddressList.length > 0) {
      showDdressList = true
    }
    return (
      <>
        <>
          {showDdressList ? "" : <div className='personalCentre_deliveryAddress_sty_center'>
            <div className='no_shipping_address_sty'>
              <div>
                <div className='no_news_yet_title'>
                  <InfoCircleOutlined style={{ fontSize: '36px' }} />
                  <div className='font-size-20'>{intl.get("NoShippingAddressAvailable")}</div>
                </div>
                <div className='no_news_yet_context'>{intl.get("PleaseAddShippingAddressBelow")}</div>
              </div>
            </div>
          </div>}
        </>

        <div className={showDdressList ? "personalCentre_deliveryAddress_sty" : 'personalCentre_deliveryAddress_sty_center'}>
          <div className='my_orders_address_width'>
            {ddressList.map((item, index) =>
              <div className={item.isDefault == "1" ? 'form_deliveryAddressList_info_blue' : 'form_deliveryAddressList_info'}>
                <div className='font-size-20'>
                  {/* <Checkbox onChange={this.props.onCheckbox} checked={item.isDefault}>Checkbox</Checkbox> */}
                  <div className="myOrder_delivery_address_sty" >
                    <div>{item.recieveName}</div>
                    <div className='margin-left-20'>{item.phone}</div>
                    {item.isDefault == "1" ? <div className='default_address_sty margin-left-20'>{intl.get("default")}</div> : ""}
                  </div>
                  <div className="myOrder_deliveryAddress_sty">{item.recieveAddress}</div>
                </div>
                <div className='myOrder_operation'>
                  {item.isDefault == "1" ? "" : <div className='color-blue' onClick={() => this.onNewDefaultAddress(item.keyId)}>{intl.get("setAsDefault")}</div>}
                  {/* {item.isDefault == "1" ? "" : <div className='color-red' onClick={() => this.onDeleteAddress(item)}>删除</div>} */}
                </div>
              </div>
            )
            }
          </div>
          <div className={showDdressList ? "new_address_sty_noWidth my_orders_address_width" : 'new_address_sty'}>
            <Form
              // name="basic"
              ref={this.formRef}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              layout="vertical"
              onClick={this.onClickNewAddress}
            >

              <div style={{ marginBottom: "20px" }}>{intl.get("addNewAddress")}</div>
              <div className='form_deliveryAddress' >
                <Form.Item label={intl.get("addressee")} name="recieveName"
                  className='form_item_deliveryAddress'

                  rules={[
                    {
                      required: true,
                      message: 'Please input your recieveName!',
                    },
                  ]}
                >
                  <Input size="large" placeholder={intl.get("enterRecipientName")} />
                </Form.Item>
                <Form.Item label={intl.get("telephoneNumber")} name="phone"
                  className='form_item_deliveryAddress'

                  rules={[
                    {
                      required: true,
                      message: intl.get("enterContactNumber"),
                    },
                  ]}
                >
                  <InputNumber placeholder={intl.get("enterContactNumber")} size="large" controls={false} style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className='form_deliveryAddress' >
                <Form.Item label={intl.get("landline")} name="tel"
                  className='form_item_deliveryAddress'
                >
                  <InputNumber placeholder={intl.get("enterLandline")} size="large" controls={false} style={{ width: "100%" }} />

                </Form.Item>
                <Form.Item label={intl.get("fax")} name="fax"
                  className='form_item_deliveryAddress'


                >
                  <InputNumber placeholder={intl.get("enterFax")} size="large" controls={false} style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div  >
                <Form.Item label={intl.get("deliveryAddress")} name="recieveAddress"
                  className='form_item_deliveryAddress_80'

                  rules={[
                    {
                      required: true,
                      message: intl.get("enterShippingAddress"),
                    },
                  ]}
                >
                  <Input size="large" placeholder={intl.get("enterShippingAddress")} />
                </Form.Item>
              </div>
            </Form>

            <Button size="large" style={{ float: "right" }} onClick={this.onFinish} type="primary" ghost htmlType="submit">
              {intl.get("increase")}
            </Button>
          </div>

        </div >
      </>



    )
  }
}
