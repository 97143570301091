export function browserLanguage() {
  var language = navigator.language || navigator.userLanguage;
  if (language === "zh-HK" || language === "zh-TW") {
    language = "zh-TW";
  } else if (language === "zh" || language === "zh-CN") {
    language = "zh-CN";
  } else if (language !== "en-US") {
    language = "en-US";
  }

  return language;
}

