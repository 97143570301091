import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { listShop } from "@/utils/user";
import { getTotal, getCartProducts } from "../../../reducers";
import { addToCart, addToWishlist, addToCompare } from "../../../actions";
import { getVisibleproducts } from "../../../services";
import ProductListItem from "./product-list-item";
import { putProductInfo } from "@/storage/PageParamers";
import {
  Pagination, Tree, Row, Col, Spin
} from 'antd';
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { getMainStoreKeyId } from "@/storage/SessionParamers"

import {
  List
  , InfiniteScroll
} from "antd-mobile"
let pageContext
const errorPhoto = require('@/images/errorPhoto.png')
// @connect()
class ProductListing extends Component {
  constructor(props) {
    super(props);
    loadLocales('collection')
    this.state = {
      hasMoreItems: true,
      listShop: [],
      cancel: true,
      productInfo: [],
      pagination: {
        current: 1,
        pageSize: 2,
        pages: 0,
        total: 0
      },
      current: 1,
      hasMore: true
    };
    pageContext = this
  }
  componentWillMount() {
    this.fetchMoreItems();
    this.props.onChildEvent(this);
  }

  fetchMoreItems = () => {
    const { productInfo, listShop } = pageContext.state;
    if (productInfo.total <= listShop.length) {
      pageContext.setState({ hasMoreItems: false });
      return;
    }
    // a fake async api call

    if (pageContext.state.cancel) {
      pageContext.listShop();
    }
  };

  listShop(val) {
    const { pagination } = this.state
    let params = {
      current: pagination.current,
      mainStoreKeyId: getMainStoreKeyId(),
      order: pageContext.props.sort,
      size: pagination.pageSize,
      sort: "sales",
    };
    if (val) {
      if (val.sortBy == "4") {
        params.sort = "price"
        params.order = "desc"
      } else if (val.sortBy == "3") {
        params.sort = "price"
        params.order = "asc"
      } else if (val.sortBy == "2") {
        params.order = "asc"
        params.sort = "sales"
      } else if (val.sortBy == "1") {
        params.order = "desc"
        params.sort = "sales"
      }
      if (val.onSearch) {
        params.searchKey = val.onSearch

      }
    }

    const { cateCode } = this.state;
    if (cateCode) {
      params.cateCode = cateCode
    }
    pageContext.setState({ cancel: false });
    listShop(params).then(res => {
      //接收后台返回的数据
      if (res.pages > 1) {
        pageContext.setState({
          hasMore: true
        });
      }
      if (res && res.records) {
        putProductInfo(res.records);
        let pagination = {
          current: res.current,
          pageSize: res.size,
          pages: res.pages,
          total: res.total,
        };
        pageContext.setState({
          productInfo: res,
          listShop: res.records,
          pagination
        });
        pageContext.props.onRefresh();
      }
    });
    // let data = await listShop(params);
  }

  onTreeSelect = (selectedKeys, e) => {
    let cateCode
    if (e.selectedNodes.length > 0) {
      cateCode = e.node.cateCode;
    } else {
      cateCode = false;
    }
    this.setState({ cateCode }, () => {
      pageContext.listShop();
    });
  }

  onTreeSelectCateCode = (cateCode) => {

    this.setState({ cateCode }, () => {
      pageContext.listShop();
    });
  }
  loadMore = () => {
    const { pagination } = this.state
    let newPagination = pagination
    if (pagination.pages > 1) {
      newPagination.pageSize += 10
      this.setState({ pagination: newPagination, hasMore: false }, () => { this.listShop() })
    }
  }
  render() {
    const {
      products,
      addToCart,
      symbol,
      addToWishlist,
      addToCompare,
      tabskeys,
      listShopCate

    } = this.props;
    const pageSizeOptions = tabskeys == "first" ? [10, 20, 30, 40] : [15, 30, 45]
    const { listShop, hasMore, productInfo, size, cancel } = this.state;
    let treeData = []
    const dig = (children) => {
      const list = [];
      // if (listShopCate[tabskeys] && listShopCate[tabskeys].children) {
      for (let i = 0; i < children.length; i++) {
        const key = children[i].key;
        const cateCode = children[i].cateCode;
        const treeNode = {
          title: children[i].title,
          cateCode,
          key,
        };
        if (children[i].children && children[i].children.length > 0) {
          treeNode.children = dig(children[i].children);
        }
        list.push(treeNode);
      }
      return list;
    };

    if (listShopCate[tabskeys] && listShopCate[tabskeys].children) {
      treeData = dig(listShopCate[tabskeys].children);
    }
    return (
      <div className="Product-Listing">
        <List>
          {listShop.map((item, index) => (
            <List.Item key={item.keyId}>
              <ProductListItem
                product={item}
                symbol={symbol}
                onAddToCompareClicked={() => addToCompare(item)}
                onAddToWishlistClicked={() => addToWishlist(item)}
                onAddToCartClicked={addToCart}
                key={item.keyId}
              /></List.Item>
          ))}
        </List>
        <InfiniteScroll style={{ marginBottom: "48px" }} loadMore={() => this.loadMore()} hasMore={hasMore} />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: getVisibleproducts(state.data, state.filters),
  symbol: state.data.symbol
});

export default connect(mapStateToProps, {
  addToCart,
  addToWishlist,
  addToCompare
})(ProductListing);
