import React, { Component } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Input, Form, Table, Spin, Pagination } from 'antd'
import { myBought, myOrders, addCar, listCar, getDetail } from '@/utils/user'
import { getOwnerKeyId, getMainStoreKeyId } from "@/storage/SessionParamers";
import { toast } from "react-toastify";
import { getToken } from "@/storage/LocalParamers";
import { Link } from "react-router-dom";
import { cartNumber, purchaseNow } from "@/actions";
import { connect } from 'react-redux'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
const errorPhoto = require('@/images/errorPhoto.png')

class PurchasedGoods extends Component {
  constructor(props) {
    super(props)
    loadLocales('PersonalCentre')
    this.state = {
      myBoughtList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      keyWords: false
    }
  }

  componentDidMount() {
    this.getMyBought()
  }

  getMyBought() {
    const { pagination, keyWords } = this.state
    let params = {
      mainStoreKeyId: getMainStoreKeyId(),
      ownerKeyId: getOwnerKeyId(),
    }
    params = { ...params, ...pagination }
    if (keyWords) {
      params.keyWords = keyWords
    }
    this.setState({ loading: true });

    myBought(params).then(res => {
      this.setState({ loading: false });
      if (res && res.data && res.responseCode === "00" && res.data.records) {
        let pagination = {
          current: res.data.current,
          pageSize: res.data.size,
          total: res.data.total,
        }
        this.setState({ myBoughtList: res.data.records, pagination });
      }
    })
  }
  onFinish = (values) => {
    this.getMyBought()
  };
  onFinishFailed = (errorInfo) => {
  };

  onAddToCart = (item) => {
    let params = {
      outMainStoreKeyId: getMainStoreKeyId(),
      ownerKeyId: getOwnerKeyId(),
      quantity: 1,
      relationGoodKeyId: item.keyId,
      skuKeyId: item.skuKeyId
    };
    addCar(params).then(res => {
      if (res.responseCode == "00") {
        this.listCars();
        toast.success(`${res.responseMessage}`);
      } else {
        toast.error(res.responseMessage);
      }
    });
  };

  listCars() {
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList) {
          if (res.data.shopCarVOList.length > 0) {
            let shopCarNum = 0
            res.data.shopCarVOList.map(res2 => res2.quantity ? shopCarNum += res2.quantity : "")
            this.props.cartNumber(shopCarNum)
          }
        } else {
          this.props.cartNumber(0)
        }
      }
    });
  }

  BuynowClicked = (item, quantity) => {
    const param = {
      skuKeyId: item.skuKeyId
    }
    item.bought = true
    getDetail(param).then(res => {
      if (res) {
        this.props.purchaseNow(res, quantity)
        this.props.history.push(`${process.env.PUBLIC_URL}/checkout`)
      }
    })
  }

  onChange = (e) => {
    if (e.target.value) {
      this.setState({ keyWords: e.target.value })
    } else {
      this.setState({ keyWords: false }, () => this.getMyBought())
    }
  };
  onChangeTable = (pagination, filters, sorter) => {
    this.setState({ pagination }, () => { this.getMyBought() })
  }

  render() {
    const { myBoughtList, pagination, loading, keyWords } = this.state

    const columns = [
      {
        title: intl.get("serialNumber"),
        key: 'No.',
        width: 65,
        render: (text, record, index) => <a>{index + 1}</a>,
      },
      {
        title: intl.get('productImage'),
        width: 112,
        dataIndex: 'mainPicture',
        align: 'center',
        render: (item) => <img onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }} className='img_sty' src={item} alt="" />
      },
      {
        title: intl.get('productName'),
        width: 300,
        dataIndex: 'produceName',
        align: 'left',
        render: (item) => <div className='purchasedGoods_produceName_sty' >{item}</div>
      },
      {
        title: intl.get('unit'),
        width: 80,
        dataIndex: 'unitName',

      },
      {
        title: intl.get('lastPurchased'),
        width: 250,
        align: 'right',
        dataIndex: 'salePriceDateHHmmFortmat',

      },
      {
        title: intl.get('operation'),
        align: 'left',
        // dataIndex: 'createDateFortmat',
        render: (item) =>
          <div className='purchasedGoods_operation_sty' >
            <div onClick={() => this.onAddToCart(item)}>{intl.get('addtoCart')}</div>
            <div>
              <a style={{ color: "red" }}
                // href={`${process.env.PUBLIC_URL}/checkout`}
                onClick={() => this.BuynowClicked(item, 1)}
              >
                {intl.get("BuyNow")}
              </a>
            </div>
          </div>
      },

    ]
    return (
      <>
        <Form
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
          layout="inline"

        >
          <Form.Item
            label={intl.get('productName')}
            name="orderNo"
          >
            <Input placeholder={intl.get("Keywords")} value={keyWords} allowClear onChange={this.onChange} />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
          >
            {intl.get("search")}
          </Button>
        </Form>
        {loading == true ? <div className='loading_myOrder'><Spin size="large" /></div> :
          !loading && myBoughtList.length === 0 && !keyWords ?
            <div className='no_news_yet'>
              <div className='no_news_yet_title'>
                <InfoCircleOutlined style={{ fontSize: '36px' }} />
                <div className='font-size-20'>{intl.get("NoPurchasedItemsYet")}</div></div>
              <div className='no_news_yet_context'>{intl.get("YouCanViewPurchasedItemsHere")}</div>
              <div>
                <a className='registration_successful_backHome'
                  href={`${process.env.PUBLIC_URL}/`}

                >{intl.get("returnToHomepage")}</a>
                <a className='registration_successful_commodityCentre'
                  href={`${process.env.PUBLIC_URL}/no-sidebar/collection`}

                >{intl.get('productCenter')}</a>
              </div>
            </div>
            :
            <div className='my_orders'>

              <div className='flip_flop_footer'>


                <div> {intl.get("ClickProductNameForDetails")} </div>
              </div>


              <div className='my_orders'>
                <Table
                  id="table"
                  scroll={{ y: '100%' }}
                  columns={columns}
                  pagination={pagination}
                  dataSource={myBoughtList}
                  loading={loading}
                  onChange={this.onChangeTable}
                />
              </div>
            </div>

        }

      </>

    )
  }
}

const mapStateToProps = state => ({

})
export default connect(
  mapStateToProps,
  { cartNumber, purchaseNow }
)(PurchasedGoods);