import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual'

// Custom Components
import HeaderOne from './common/headers/header-one';
import { connect } from "react-redux";
import FooterOne from "./common/footers/footer-one";
import intl from "react-intl-universal";
// ThemeSettings
import { withRouter } from "react-router-dom";
import { Badge, TabBar } from 'antd-mobile';
import {
  AppstoreOutline,
  ShopbagOutline,
  GiftOutline,
  UserOutline,
} from 'antd-mobile-icons'
import './app.scss'
// @withRouter
class App extends Component {
  onChange = (key) => {
    if (key === "1") {
      this.props.history.push(`/`)
    } else if (key === "2") {
      this.props.history.push(`${process.env.PUBLIC_URL}/no-sidebar/collection`)
    } else if (key === "3") {
      this.props.history.push(`${process.env.PUBLIC_URL}/cart`)
    } else if (key === "4") {
      this.props.history.push(`${process.env.PUBLIC_URL}/personal-centre`)
    }
  }

  render() {
    let currentPage = [];
    let title = "";
    if (this.props.children && this.props.children.length > 0) {
      currentPage = this.props.children.filter(res => res.props.path.includes(this.props.location.pathname))
      if (currentPage[0] && currentPage[0].props.title) {
        title = currentPage[0].props.title;
      }
    }
    const tabs = [
      {
        key: '1',
        title: '首页',
        icon: < AppstoreOutline />,
      },
      {
        key: '2',
        title: '商品中心',
        icon: < GiftOutline />,
      },
      {
        key: '3',
        title: '购物车',
        icon: <ShopbagOutline />,
        badge: this.props.cartNumbers,
      },
      {
        key: '4',
        title: '我的',
        icon: <UserOutline />,
      },
    ]

    return (
      < div className='app_style'>
        <HeaderOne headerName={title} history={this.props.history} />
        {this.props.children}
        {/* <FooterOne logoName={'logo.png'} /> */}
        <div className='TabBar-Style'>
          <TabBar activeKey="4" onChange={this.onChange}>
            {tabs.map(item => (
              <TabBar.Item
                key={item.key}
                icon={item.icon}
                title={item.title}
                badge={item.badge}

              />
            ))}
          </TabBar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    cartNumbers: state.cartNumber.cart,
  };
};

export default connect(mapStateToProps)(withRouter(App));
