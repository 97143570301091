import React, { useRef, useEffect, useState } from 'react';
import {
  Modal, Row, Col, Spin, Table
} from "antd";
import intl from 'react-intl-universal';
import './details-price.css'

const errorPhoto = require('@/images/errorPhoto.png')
export default function AddingProductsExistingOrderModal(props) {
  const [refreshState, setRefreshState] = useState(true)
  const [fulfil, setFulfil] = useState(false)
  const scrollDivRef = useRef(null);

  const handleScroll = () => {
    const { current } = scrollDivRef;
    if (current.scrollHeight - current.scrollTop === current.clientHeight) {
      if (props.myOrdersPagination.size >= props.myOrdersPagination.total) {
        setFulfil(true)
      }
      if (refreshState && props.myOrdersPagination.size < props.myOrdersPagination.total) {
        setRefreshState(false)
        props.loaderOrderList((res) => { setRefreshState(true) })
      }

      // 在这里调用你的函数或者设置状态
    }
  };

  useEffect(() => {
    const { current } = scrollDivRef;
    if (current) {
      current.addEventListener('scroll', handleScroll);
      return () => current.removeEventListener('scroll', handleScroll);
    }

  }, [refreshState]);
  const columns = [
    {
      title: intl.get("sequence"),
      key: 'sequence',
      render: (text, record, index) => <div>{index + 1}</div>,
    },
    {
      title: intl.get("productImage"),
      dataIndex: 'mainPicture',
      key: 'mainPicture',
      render: (item) => <img onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }} className='img_sty' src={item} alt="" />
    },
    {
      title: intl.get("productName"),
      dataIndex: 'produceName',
      key: 'produceName',
    },

    {
      title: intl.get("quantity"),
      dataIndex: 'orderQty',
      key: 'orderQty',
    },



  ];
  return (
    <Modal
      title={intl.get("AddtoExistingOrder")}
      open={props.open}
      center={"true"}
      onCancel={props.onCloseAddProdModal}
      width={1200}
      centered="true"
      footer={null}
    >
      <div className="orderModal">
        <Row>
          <Col ref={scrollDivRef} span={8} className="prodmodel_myOrdersList">

            {!props.myOrdersList ? "" : props.myOrdersList.map((item, index) =>
              <div
                onClick={(e) => { props.onCheckbox(index); props.getOrderDetail(item) }}
                key={item.keyId}
                className={props.useAddress == index ? "prod_radio_frame" : "prod_radio_frame_none"} >
                <div className='font-size-20 prodmodel_order_info'>
                  <div >
                    <div className='text_grey'>{item.createDateFortmat}</div>
                    <div className='prodmodel_order_info_prodQty'>
                      <span className='text_grey_85 fontSize-16'>{intl.get("NumberItems")}</span>
                      <span className='text_grey'>{item.prodQty}</span>
                    </div>
                  </div>
                  <div>
                    <div className='text_grey_85'>{item.orderNo}</div>
                    <div className='blue_text'> {"$ " + item.amtValue.toFixed(2)}</div>
                  </div>

                </div>
              </div>
            )}
            <Spin className='prodmodel_spin' spinning={!refreshState} />
            {fulfil ? <div className='prodmodel_spin' >已經到底啦!</div> : ""}
          </Col>
          <Col span={16}>
            {/* <div className='prod_list'>11222</div> */}
            <Table loading={props.orderDetailLoading} columns={columns} dataSource={props.orderDetailList} pagination={false} />
          </Col>

        </Row>

      </div>
      <div className='prodmodel_button'>
        <div onClick={() => props.addProdmodel()} className='blue_border_text'>確定加入</div>
      </div>
      {/* <button
          className="newBtn btn btn-secondary"
          onClick={this.onCloseConfirmModal}
        >
          {intl.get("cancel")}
        </button> */}

    </Modal >
  )
}

