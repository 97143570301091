import React, { Component } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Col, Row, Badge } from 'antd';
import { Link } from "react-router-dom";
import { getLoginInfo } from '@/storage/LocalParamers'
import { putSelectGoods } from '@/storage/SessionParamers'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
const img_noPhoto = require("@/assets/images/portfolio/img_noPhoto.png")
const errorPhoto = require('@/images/errorPhoto.png')
class ProductsList extends Component {
  constructor(props) {
    super(props);
    loadLocales('layouts')
    this.state = { listAnMouth: props.listAnMouth, displayType: props.displayType };
  }
  render() {
    const { displayType, listAnMouth } = this.props
    return (
      <>{listAnMouth.length > 0 ?
        <div className='list_home_sty'>
          <div className='list_title_sty'>
            <Badge count={listAnMouth.length}>
              <div className="productList_title_sty">{displayType == "1" ? intl.get("specialOffer") : intl.get("newProducts")}</div>
            </Badge>

            <div onClick={() => this.props.history.push(`${process.env.PUBLIC_URL}/no-sidebar/collection`)} className='productList_all_sty'>
              <div className='productList_all_title_sty'>{intl.get("showAll")} </div>
              <RightOutlined
                size={8}
                className={displayType == "1" ? 'productList_all_icon_sty_red' : 'productList_all_icon_sty_blue'} />
            </div>
          </div>
          <Row gutter={16} className="gutter-row">
            {listAnMouth.map((item, index) => {
              if (index < 8) {
                return <Col key={item.keyId} >
                  <Link to={`${process.env.PUBLIC_URL}/no-sidebar/product/${item.keyId}`}
                    onClick={() => { putSelectGoods(item.goodsCode) }}
                    className='productList_list_item_sty'>
                    <div className='img_product_picture'>
                      <img
                        src={item.mainPicture}
                        // onerror="this.src={img_noPhoto}"
                        className="img-fluid img_product_picture"
                        alt=""
                        onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
                      />
                      {displayType == "1" ?
                        <div className='productList_offers_red'>{intl.get("discount")}</div>
                        : <div className='productList_offers_blue'>{intl.get("newest")}</div>}

                    </div>
                    <div className='productList_item_info_sty'>
                      <div title={item.goodsName} className='productList_item_title_sty'>{item.goodsName}</div>
                      {getLoginInfo() ? <div className='productList_item_price_sty'>$ {displayType == "1" ? item.specialPrice : item.price}</div> : ""}
                    </div>
                  </Link>
                </Col>
              }
            }

            )}
          </Row>

        </div>
        : ""}</>
    );
  }
}

export default ProductsList;