
import React, { Component } from "react";
import "./index.css";
import Breadcrumb from "../common/breadcrumb";
import { listCar, carDelete, changeQty } from "@/utils/user";
import { Tabs } from 'antd';
import MyOrders from '@/components/PersonalCentre/MyOrders';
import PurchasedGoods from '@/components/PersonalCentre/PurchasedGoods';
import AddressController from '@/components/PersonalCentre/AddressController'
import AccountInfo from '@/components/PersonalCentre/AccountInfo'
import { connect } from "react-redux";
import { cartNumber, cartNumberDelect } from "@/actions";
import { UserOutlined } from '@ant-design/icons'
import MyContext from '@/components/MyContext'
import { removeToken, removeUserName } from '@/storage/LocalParamers.js'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
let pageContext
class PersonalCentre extends Component {
  constructor(props) {
    super(props);
    loadLocales('PersonalCentre')

    this.state = {
      tabsState: "1",
      uesrname: false
    };
    pageContext = this
  }
  componentDidMount() {
    this.listCars();
  }

  listCars() {
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList) {
          if (res.data.shopCarVOList && res.data.shopCarVOList.length > 0) {
            let shopCarNum = 0
            res.data.shopCarVOList.map(res2 => res2.quantity ? shopCarNum += res2.quantity : "")
            this.props.cartNumber(shopCarNum)
          } else {
            this.props.cartNumber(0)
          }
          this.setState({
            shopCarVOList: res.data.shopCarVOList
          });
        }
        this.setState({
          listCars: res.data
        });
      }
    });
  }
  onChange = (key) => {
    this.setState({ tabsState: key });
  };

  userNameFun(uesrname) {
    if (uesrname) {
      pageContext.setState({ uesrname })

    }
  }

  onClLogOut() {
    removeUserName()
    removeToken();
    this.props.cartNumberDelect();
    this.props.history.push("/pages/login")
  }
  render() {
    const { listCars,
      symbol,
      shopCarVOList,
      tabsState,
      uesrname
    } = this.state;

    const items = [
      {
        key: "1",
        label: <div style={{ padding: "9px 30px" }}>{intl.get("MyOrders")}</div>,
      },
      {
        key: "2",
        label: <div style={{ padding: "9px 30px" }}>{intl.get("PurchasedItems")}</div>,
      },
      {
        key: "3",
        label: <div style={{ padding: "9px 30px" }}>{intl.get("deliveryAddress")}</div>,
      },
      {
        key: "4",
        label: <div style={{ padding: "9px 30px" }}>{intl.get("AccountInformation")}</div>,
      }
    ]
    let tabsPage = ""
    if (tabsState === "1") {

      tabsPage = <MyOrders history={this.props.history} userNameFun={this.userNameFun}></MyOrders>
    } else if (tabsState === "2") {

      tabsPage = <PurchasedGoods history={this.props.history}></PurchasedGoods>
    } else if (tabsState === "3") {
      tabsPage = <AddressController history={this.props.history}></AddressController>

    } else if (tabsState === "4") {
      tabsPage = <AccountInfo history={this.props.history}></AccountInfo>

    }
    return (
      <div>
        {/*SEO Support*/}
        {/*SEO Support End */}
        <Breadcrumb title={intl.get("PersonalCenter")} />
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="personalCentre_tabs">
              <Tabs defaultActiveKey="1"
                // onSelect={this.onTreeSelect}
                items={items} onTabClick={this.onChange} />
              <div className="personal_logOut">
                <UserOutlined style={{ fontSize: '30px', padding: "10px" }} />
                <div>{uesrname}
                  <span
                    className="font-color-blue"
                    // onMouseOver={this.style.cursor}
                    onClick={() => this.onClLogOut()} >
                    {intl.get("LogOut")}
                  </span>
                </div>
              </div>
            </div>
            {tabsPage}
          </div>
          <MyContext.Provider value={{ name: "chenya", age: 18 }}>
          </MyContext.Provider>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: state.data.products.find(el => el.id == productId),
    symbol: state.data.symbol
  }
}



export default connect(mapStateToProps, { cartNumber, cartNumberDelect })(PersonalCentre);
