import React, { Component } from 'react'
import { Select, Col, Row, Input } from 'antd'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";

export default class registerPage extends Component {
  render() {
    return (
      <div>
        <div className="login_title_sty">{intl.get("registrations")}</div>
        <div className="login_sty">
          <form className="theme-form" >
            <Row gutter={12}>
              <Col span={12}>
                <div className="login_form_group">
                  <label htmlFor="email">{intl.get("nicknames")}</label>
                  <div>
                    <Input
                      type="text"
                      className="fname_inputs"
                      id="fname"
                      autoComplete="new-password"
                      style={{ '--ant-input-active-border-color': '#2e3092' }}
                      placeholder={intl.get("enterNickname")}
                      required=""
                      value={this.props.fname}
                      onChange={e => this.props.onInputFnameChange(e)}
                    />
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="login_form_group">
                  <label htmlFor="email">{intl.get("loginPassword")}</label>
                  <div>
                    {/* <input
                     
                    /> */}
                    <Input.Password
                      type="password"
                      style={{ '--ant-input-active-border-color': '#2e3092' }}
                      className="fname_inputs"
                      id="pssword"
                      placeholder={intl.get("loginPassword")}
                      required=""
                      autoComplete="new-password"
                      onChange={e => this.props.onInputLoginPasswordChange(e)}
                    />
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="login_form_group">
                  <label htmlFor="phone">{intl.get("phone")}</label>
                  <div className="region_select">
                    <div className="region">
                      <Select
                        defaultValue="853"
                        bordered={false}
                        style={{ width: "120px" }}
                        onChange={this.props.onSelectCountryCode}
                        options={[
                          { value: '853', label: intl.get("macao") },
                          { value: '852', label: intl.get("hongkong") },
                          { value: '86', label: intl.get("china") },
                        ]}
                      />
                    </div>

                    <Input
                      className="phone_inputs"
                      id="mobilePhoneNumber"
                      autoComplete="new-password"
                      style={{ '--ant-input-active-border-color': '#2e3092' }}
                      placeholder={intl.get("phoneNumber")}
                      value={this.props.mobilePhoneNumber || ""}
                      onChange={e => this.props.onInputPhoneChange(e)}
                    />
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="login_form_group">
                  <label htmlFor="review">{intl.get("ConfirmPassword")}</label>
                  <div>
                    <Input.Password
                      style={{ '--ant-input-active-border-color': '#2e3092' }}
                      type="password"
                      className="fname_inputs"
                      id="confirmPassword"
                      autoComplete="new-password"
                      placeholder={intl.get("ConfirmPassword")}
                      required=""
                      onChange={e => this.props.onInputConfirmPasswordChange(e)}
                    />
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="login_form_group">
                  <label htmlFor="review">{intl.get("captcha")}</label>
                  <div className="captcha_sty">
                    <Input
                      type="text"
                      style={{ '--ant-input-active-border-color': '#2e3092' }}
                      className="captcha_inputs"
                      id="verificationCode"
                      placeholder={intl.get("pleaseEnterTheCAPTCHA")}
                      required=""
                      autoComplete="new-password"
                      value={this.props.verificationCode || ""}
                      onChange={e => this.props.onInputCaptchaChange(e)}
                    />
                    {this.props.loading ? (
                      <div className="captcha_btn_time">
                        {this.props.yztime}
                      </div>
                    ) : (
                      <div
                        onClick={this.props.smsSend}
                        className="captcha_btn"
                      >
                        {intl.get("SendCAPTCHA")}
                      </div>
                    )}
                  </div>
                </div></Col>
            </Row>
            {/* <div className="col-md-6">
                          <label htmlFor="review">姓</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lname"
                            placeholder="姓"
                            required=""
                            value={lname}
                            onChange={e => this.onInputLnameChange(e)}
                          />
                        </div> */}
            <div
              onClick={() => this.props.handleOnOK()}
              className="create_account_btn"
            >
              {intl.get("registrations")}
            </div>
          </form>
        </div>
      </div>
    )
  }
}
