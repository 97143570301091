import React, { Component } from "react";
import { Link } from "react-router-dom";
import { putSelectGoods } from '@/storage/SessionParamers'
import "./product-item.css";
const errorPhoto = require('@/images/errorPhoto.png')
class ProductItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stock: "InStock",
      quantity: 1,
      image: ""
    };
  }

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = e => {
    this.setState({ quantity: parseInt(e.target.value) });
  };
  windowOpen = (product) => {
    putSelectGoods(product.goodsCode);
    this.props.handleClick(product.keyId)
    window.open(`${process.env.PUBLIC_URL}/no-sidebar/product/${product.keyId}`, '_blank')
  }
  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      onAddToWishlistClicked,
      onAddToCompareClicked,
      handleClick
    } = this.props;
    return (
      <div className="product-box product-item">
        <div className="img-wrapper">
          <div className="lable-block">
            {product.new == true ? <span className="lable3">new</span> : ""}
            {product.sale == true ? (
              <span className="lable4">on sale</span>
            ) : (
              ""
            )}
          </div>
          <div className="front itemImg">
            <Link
              onClick={() => this.windowOpen(product)}
            >
              <img
                src={`${!product.mainPicture ? [] : product.mainPicture}`}
                className="img-fluid"
                alt=""
                onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
              />
            </Link>
          </div>
          <div className="cart-info cart-wrap">
            <button title="Add to cart" onClick={onAddToCartClicked}>
              <i className="fa fa-shopping-cart" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div className="product-detail">
          <div>
            <Link
              onClick={() => this.windowOpen(product)}
            >
              <h6>{product.goodsName}</h6>
            </Link>
            <h4>${product.price}</h4>
          </div>
        </div>
      </div >
    );
  }
}

export default ProductItem;
