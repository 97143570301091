import axios from "axios";
import serverConfig from "@/api";
import { Modal } from 'antd';
import { getToken, getTempToken } from "@/storage/LocalParamers";
import { toast } from "react-toastify";
// import { browserHistory } from "react-router";
const { confirm } = Modal;
// const history = browserHistory;
// 创建 axios 请求实例
const serviceAxios = axios.create({
  baseURL: serverConfig.baseURL, // 基础请求地址
  timeout: 10000, // 请求超时设置
  withCredentials: false, // 跨域请求是否需要携带 cookie
  useTokenAuthorization: false
});

axios.defaults.headers = {

};
// 创建请求拦截
serviceAxios.interceptors.request.use(
  config => {
    // 如果开启 token 认证
    if (config.useTokenAuthorization) {
      if (config.useTokenAuthorization === "T") {
        config.headers["Authorization"] = getTempToken()
      } else {
        if (!getToken()) {
          return
        }
        config.headers["Authorization"] = getToken();
      }
      // localStorage.getItem("token"); // 请求头携带 token

    }
    // 设置请求头
    if (!config.headers["content-type"]) {
      // 如果没有设置请求头
      if (config.method === "post" || config.method === "put" || config.method === "delete") {
        config.headers["content-type"] = "application/json; charset=utf-8"; // post 请求
        // config.data = qs.stringify(config.data); // 序列化,比如表单数据
        config.data = config.body;
      } else {
        config.headers["content-type"] = "application/json"; // 默认类型
      }
    }
    config.headers.Accept = 'application/json';
    if (config.url.substring(0, 6) == "/login") {
      config.baseURL = config.baseURL.substring(0, config.baseURL.length - 4);
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// 创建响应拦截
serviceAxios.interceptors.response.use(
  res => {
    let data = res.data;
    // 处理自己的业务逻辑，比如判断 token 是否过期等等
    // 代码块
    if (data.responseCode !== "00") {
      toast.error(data.responseMessage)
      return data;

    } else {
      return data;

    }
  },
  error => {
    let message = "";
    if (error && error.response) {
      switch (error.response.status) {
        case 403:
          // if (!window.location.pathname.includes("/pages/login")) {
          //   window.location.href = `${process.env.PUBLIC_URL}/pages/login`;
          // }
          window.localStorage.removeItem("token");
          // return <Redirect to="/403" />
          message = "登录超时,请重新登录";
          return error.response
        case 404:
          message = `请求地址出错: ${error.response.config.url}`;
          break;
        default:
          message = "异常问题，请联系管理员！";
          break;
      }
    }
    return Promise.reject(message);
  }
);
export default serviceAxios;
