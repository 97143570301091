import React, { Component } from 'react'
import { CheckCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import ProductRecommendation from '@/components/register/productRecommendation.js'
import Breadcrumb from "../common/breadcrumb";
import './index.css';
import { getToken } from "@/storage/LocalParamers";
import { putJumpLoginPage } from '@/storage/SessionParamers'

import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
class OrderSuccess extends Component {
  constructor(props) {
    super(props);
    loadLocales('OrderSuccess')
  }
  newest = ref => {
    this.newestRef = ref;
  };
  offers = ref => {
    this.offersRef = ref;
  };
  render() {
    return (
      <div>
        <Breadcrumb title={intl.get("orderConfirmation")} />
        <div style={{ marginTop: "20px" }} className="container">
          <div className='registration_successful'>
            <div className='registration_successful_scope'>
              <div className='registration_successful_icon'>
                <CheckCircleOutlined />
                <div>{intl.get("orderSuccessful")}</div>
              </div>
              <div className='registration_successful_tips'>
                {intl.get("PleaseWaitForDelivery")}“<span style={{ color: "#2e3092" }}>{intl.get("PersonalCentre-MyOrders")}</span>”{intl.get("lookIn")}。
              </div>
              <div className='order_success_btn'>
                <div className="registration_successful_keepShopping"
                  onClick={() => this.props.history.push("/no-sidebar/collection")}>
                  {intl.get("continueShopping")}
                </div>
                <div
                  className="registration_successful_viewOrder"
                  onClick={() => {
                    if (getToken()) {
                      this.props.history.push(`${process.env.PUBLIC_URL}/personal-centre`)
                    } else {
                      putJumpLoginPage(window.location.pathname);
                      this.props.history.push(`${process.env.PUBLIC_URL}/pages/login`)
                    }
                  }}>
                  {intl.get("ViewOrder")}
                </div>
              </div>
            </div>
            <div className='product_recommendation'>
              <div >

                <ProductRecommendation type={"1"} history={this.props.history} recommendation={this.offers}></ProductRecommendation>
              </div>
              <div >

                <ProductRecommendation type={"2"} history={this.props.history} recommendation={this.newest}></ProductRecommendation>
              </div>
            </div>
          </div>
        </div>
        {/*Regsiter section*/}
        <section className="register-page section-b-space">
          <div className="container container1">
            <div className="row">

            </div>
          </div >
        </section >
      </div >

    )
  }
}

export default OrderSuccess