import React, { Component } from "react";
import { IntlActions } from "react-redux-multilingual";
import './header-one.css'
// Import custom components
import store from "../../../store";
// import NavBar from "./common/navbar";
import { NavBar, Badge } from 'antd-mobile'
import LogoImage from "./common/logo";
import { cartNumber, purchaseNowDelect, cartNumberDelect } from "@/actions";
import { connect } from "react-redux";
import { getToken, removeToken } from "../../../storage/LocalParamers";
import { myOrders, listCar } from '@/utils/user.js'
import intl from "react-intl-universal";
import { UserOutline, ShopbagOutline } from 'antd-mobile-icons'
import { putJumpLoginPage } from '@/storage/SessionParamers'
import MyContext from '@/components/MyContext'
const myLogo = require("@/assets/images/portfolio/my.png");
const shop = require("@/assets/images/portfolio/shop.png");

class HeaderOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      shopCarNum: 0,
      showCartStatus: false,
      showMaOrderStatus: false,
      needFixed: false
    };
  }
  /*=====================
         Pre loader
         ==========================*/

  componentDidMount() {
    myOrders().then(res => {
      if (res && res.responseCode !== "00") {
        removeToken()
        this.props.cartNumberDelect();
      }
    })
    if (document.querySelector(".loader-wrapper")) {
      setTimeout(function () {
        document.querySelector(".loader-wrapper").style = "display: none";
      }, 1000);
    }

    this.listCars();
    let showMaOrderList = window.location.href;
    if (showMaOrderList.includes("personal-centre") || showMaOrderList.includes("login")) {
      this.setState({ showMaOrderStatus: true });
    }

    let showCartList = window.location.href;
    if (showCartList.includes("cart")) {
      this.setState({ showCartStatus: true });
    }
    this.setState({ open: true });
    window.addEventListener("scroll", this.handleScrolls);

    if (document.getElementById('fixed-menu')) {

      const fixedTop = document.getElementById('fixed-menu').offsetTop;

    }

  }
  handleScrolls = () => {
    if (document.documentElement.scrollTop > 100) {
      this.setState({ needFixed: true })
    } else if (document.documentElement.scrollTop <= 100) {
      this.setState({ needFixed: false })
    }
  };


  componentWillUnmount() {
    // window.removeAllListeners();
    window.removeEventListener("scroll", this.handleScrolls);
  }
  listCars() {
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList && res.data.shopCarVOList.length > 0) {
          let shopCarNum = 0
          res.data.shopCarVOList.map(res2 => res2.quantity ? shopCarNum += res2.quantity : "")
          this.props.cartNumber(shopCarNum)
        }
      }
    });
  }
  // handleScroll = () => {
  //   let number =
  //     window.pageXOffset ||
  //     document.documentElement.scrollTop ||
  //     document.body.scrollTop ||
  //     0;
  //   if (number >= 300) {
  //     if (window.innerWidth < 576) {
  //       document.getElementById("sticky").classList.remove("fixed");
  //     } else document.getElementById("sticky").classList.add("fixed");
  //   } else {
  //     document.getElementById("sticky").classList.remove("fixed");
  //   }
  // };

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }
  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };
  // getDerivedStateFromProps(newProps) {
  //   let showMaOrderList = window.location.href;
  //   if (showMaOrderList.includes("personal-centre") || showMaOrderList.includes("login")) {
  //     this.setState({ showMaOrderStatus: true });
  //   }

  //   let showCartList = window.location.href;
  //   if (showCartList.includes("cart")) {
  //     this.setState({ showCartStatus: true });
  //   }
  // }
  back = () => {
    this.props.history.goBack()
  }
  render() {
    const urlParams = new URL(window.location.href);
    let showStatus = false;
    if (urlParams.pathname == "/") {
      showStatus = true;
    }
    const { cartNumbers } = this.props;
    const { showCartStatus, showMaOrderStatus } = this.state
    return (
      <div>
        <div
          id='fixed-menu'
          className={`menu ${this.state.needFixed ? 'fixed' : ''}`}
        >
          <NavBar
            right={this.props.headerName == intl.get("homePage") ?

              <div  >
                <UserOutline
                  onClick={() => {
                    putJumpLoginPage(window.location.pathname)
                    this.props.history.push(getToken()
                      ? `${process.env.PUBLIC_URL}/personal-centre`
                      : `${process.env.PUBLIC_URL}/pages/login`)
                  }}
                  className="NavBar-right" fontSize={24} />
                <Badge content={!cartNumbers ? null : cartNumbers}>
                  <ShopbagOutline
                    onClick={() => { this.props.purchaseNowDelect(); this.props.history.push(`${process.env.PUBLIC_URL}/cart`) }}
                    className="NavBar-right" fontSize={24} />
                </Badge>
              </div> : false}
            back={this.props.headerName == intl.get("homePage") ? null : ''}
            onBack={this.back}>
            {this.props.headerName == intl.get("homePage") ? "博士文具" : this.props.headerName}
          </NavBar>
        </div>

      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    cartNumbers: state.cartNumber.cart,
    key: props.location
  };
};
export default connect(mapStateToProps, { cartNumber, purchaseNowDelect, cartNumberDelect })(HeaderOne);
