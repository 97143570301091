import React, { Component } from "react";
import { Grid, Swiper, Image, Space } from 'antd-mobile'
import "../../common/index.scss";
import { listCarousel, listAnMouth, listSpecProd } from "@/utils/user";
// Import custom components
import ProductsList from "../common/ProductsList";
import HeaderOne from "../../common/headers/header-one";
import FooterOne from "../../common/footers/footer-one";
import { putProductInfo } from "@/storage/PageParamers";
import { Empty } from 'antd';
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import "./main.css";
let pageContext
//优惠列表
const listOfOffers = 1;
//新商品列表
const newProductsList = 2
class Kids extends Component {
  constructor(props) {
    super(props);
    loadLocales('layouts')
    this.state = { data: [], listAnMouth: [], refresh: false, listSpecProd: [] };
    pageContext = this
  }
  componentDidMount() {
    this.listCarousel();
    this.listAnMouth()
    this.listSpecProd()
    document
      .getElementById("color")
      .setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color10.css`);
  }
  listCarousel() {
    let params = {
      // email: "123",
      // password: "12321"
    };
    listCarousel(params).then(res => {
      //接收后台返回的数据
      if (res && res.data) {
        this.setState({ data: res.data });
      }
    });
  }
  listAnMouth() {
    let params = { ShopGoodsCenterSearch: {} };
    listAnMouth(params).then(res => {
      if (res) {
        pageContext.setState({ listAnMouth: res.records }, () => { pageContext.setState({ refresh: !pageContext.state.refresh }) });
        putProductInfo(res.records);
      }
    });
  }

  listSpecProd() {
    let params = {};
    listSpecProd(params).then(res => {
      if (res) {
        pageContext.setState({ listSpecProd: res.records }, () => { pageContext.setState({ refresh: !pageContext.state.refresh }) });
        putProductInfo(res.records);
      }
    });
  }

  render() {
    const { data, listAnMouth, listSpecProd } = this.state;
    return (
      <>
        <HeaderOne headerName={intl.get("homePage")} history={this.props.history} />
        <Space direction='vertical'>
          <Grid columns={1} gap={1}>
            <Grid.Item className="swiper-Image">
              <Swiper
                autoplay
                loop
                stuckAtBoundary={false}
                style={{ '--height': "200px" }}
              >
                {data.map(item => (
                  <Swiper.Item
                    onClick={() => this.props.history.push(`${process.env.PUBLIC_URL}/no-sidebar/collection`)}
                    key={item.keyId}>
                    <Image className='content' src={item.imageUrl} />
                  </Swiper.Item>
                ))}
              </Swiper>
              <div className="swiper-Image-title">
                <div id="swiper-title1">{intl.get("fallNewArrivals")}</div>
                <div id="swiper-title2">{intl.get("BTSStationerySale")}</div>
              </div>
            </Grid.Item>
            <Grid.Item style={{ margin: '10px 0' }}>
              <ProductsList displayType={listOfOffers} listAnMouth={listSpecProd} history={this.props.history} />
            </Grid.Item>
            <Grid.Item style={{ margin: '10px 0' }}>
              <ProductsList displayType={newProductsList} listAnMouth={listAnMouth} history={this.props.history} />
            </Grid.Item>
          </Grid>
        </Space>
        <FooterOne />


      </>
    );
  }
}

export default Kids;
