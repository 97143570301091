/*
只用于在页面间传递参数
*/
export function putKeyId(keyId) {
  sessionStorage.setItem("keyId", keyId);
}

export function getKeyId() {
  return sessionStorage.getItem("keyId");
}

export function putProductInfo(productInfo) {
  sessionStorage.setItem("productInfo", JSON.stringify(productInfo));
}

export function getProductInfo() {
  let productInfo = sessionStorage.getItem("productInfo");
  return JSON.parse(productInfo);
}

export function putProduceExtrproperties(produceExtrproperties) {
  sessionStorage.setItem(
    "produceExtrproperties",
    JSON.stringify(produceExtrproperties)
  );
}

export function getProduceExtrproperties() {
  let produceExtrpropertiesAry = sessionStorage.getItem(
    "produceExtrproperties"
  );
  if (
    produceExtrpropertiesAry === null ||
    produceExtrpropertiesAry === "null" ||
    produceExtrpropertiesAry === "" ||
    produceExtrpropertiesAry === undefined
  ) {
    produceExtrpropertiesAry = [];
    return produceExtrpropertiesAry;
  } else {
    return JSON.parse(produceExtrpropertiesAry);
  }
}

export function putProduceListAnMouth(produceListAnMouth) {
  sessionStorage.setItem(
    "produceListAnMouth",
    JSON.stringify(produceListAnMouth)
  );
}

export function getProduceListAnMouth() {
  let produceListAnMouth = sessionStorage.getItem("produceListAnMouth");
  if (
    produceListAnMouth === null ||
    produceListAnMouth === "null" ||
    produceListAnMouth === "" ||
    produceListAnMouth === undefined
  ) {
    produceListAnMouth = [];
    return produceListAnMouth;
  } else {
    return JSON.parse(produceListAnMouth);
  }
}

export function putSpecialOffer(specialOffer) {
  if (specialOffer) {
    sessionStorage.setItem("specialOffer", JSON.stringify(specialOffer));
  }
}

export function getSpecialOffer() {
  let specialOffer = sessionStorage.getItem("specialOffer");
  return JSON.parse(specialOffer);
}


export function putLatestProducts(latestProducts) {
  if (latestProducts) {
    sessionStorage.setItem("latestProducts", JSON.stringify(latestProducts));
  }
}

export function getLatestProducts() {
  let latestProducts = sessionStorage.getItem("latestProducts");
  return JSON.parse(latestProducts);
}
