import React from "react";
import { Link } from "react-router-dom";
import logoPoksi from "@/assets/images/portfolio/papelarlasPokSi.png";
const errorPhoto = require('@/images/errorPhoto.png')
function LogoImage(props) {
  return (
    <Link to={`${process.env.PUBLIC_URL}/`}>
      <img
        src={logoPoksi}
        style={{
          width: " 149px",
          height: " 40px"
        }}
        alt=""
        onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
      // className="img-fluid"
      />
    </Link>
  );
}

export default LogoImage;
