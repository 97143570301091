import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getBestSeller } from "../../services";
import { addToCart, addToWishlist, addToCompare } from "../../actions";
import ProductItem from "../layouts/common/product-item";
import { getCurCode } from "@/storage/LocalParamers";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";

class RelatedProduct extends Component {
  constructor(props) {
    super(props);
    loadLocales('common')
  }
  render() {
    const {
      items,
      symbol,
      addToCart,
      addToWishlist,
      addToCompare,
      listSelectRelevantGoods,
      handleClick
    } = this.props;

    return (
      <section style={{ paddingTop: 0 }}>
        <div className="container">
          <div className="row">
            <div className="col-12 product-related">
              <h2>{intl.get("relatedProducts")}</h2>
            </div>
          </div>
          <div className="row search-product">
            {listSelectRelevantGoods.map((product, index) => (
              <div
                key={index}
                style={{ height: "270px" }}
                className="col-xl-2 col-md-4 col-sm-6"
              >
                <ProductItem
                  product={product}
                  symbol={symbol}
                  onAddToCompareClicked={() => addToCompare(product)}
                  handleClick={handleClick}
                  onAddToWishlistClicked={() => addToWishlist(product)}
                  onAddToCartClicked={() => addToCart(product, 1)}
                  key={index}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    items: getBestSeller(state.data.products),
    symbol: getCurCode()
  };
}

export default connect(mapStateToProps, {
  addToCart,
  addToWishlist,
  addToCompare
})(RelatedProduct);
