import React from 'react'
import { Modal, Radio, Divider, Form, Input, Button, InputNumber } from 'antd';
import { QRCodeSVG, QRCodeCanvas } from 'qrcode.react' // 有两种都可以用来生成二维码，格式不同
import simplePay from '@/components/checkout/image/simplePay.png';
import { toast } from "react-toastify";
import { cancelOrder } from '@/utils/user.js'
import intl from "react-intl-universal";
export default function payModal(props) {
  return (
    <Modal
      open={props.payQRcodeState}
      title={intl.get("SelectPaymentMethod")}
      width={800}
      // onOk={handleOk}
      onCancel={props.onPayQRcodeCancel}
      footer={[
        // <Button key="back" onClick={handleCancel}>
        //   Return
        // </Button>,
      ]}
    >
      <Divider />
      <div className='pay_info_sty'>
        <div>
          <Button
            onClick={props.cancelOrderFun}
            danger size='large'
            className="orderCancellation_btn_sty"
          >
            {intl.get("OrderCancellation")}
          </Button>
          <div >
            <div className='font-size-16 color-grey'>{intl.get("orderNumber")}</div>
            <div className='font-size-20 color-grey-85'>{props.payInfo.orderNo}</div>
          </div>
        </div>
        <div>
          <div>
            <div className='font-size-16 color-grey'>{intl.get("orderAmount")}</div>
            <div className='font-size-20 color-red font-weight-600'>$ {props.payInfo.payAmount}</div>
          </div>
          <Button
            onClick={props.onPayQRcodeCancel}
            size='large'
            type="primary" ghost
            className='COD_btn_sty'
          >
            {intl.get("CashOnDelivery")}
          </Button>
        </div>
      </div>
      <div className='QRCode_sty'>
        <QRCodeSVG
          value={props.payInfo.payQrcode} // 生成的二维码的链接，比如：https://m.baidu.com/
          size={300} // 尺寸大小，number类型，默认128
        // includeMargin='boolean' //false、true,是否支持marginSize
        // marginSize={number} // 指定用于符号周围边距的模块数。(我的理解就是展示大小)
        // imageSettings={objec} // 对象，表示二维码中图像的展示
        />
      </div>
      <div className='QRCode_sty font-size-20 color-grey-85'>{intl.get("SupportsPaymentViaQRCodeontheFollowingApps")}</div>
      <div className='simplePay_sty'><img src={simplePay} alt="" /></div>

    </Modal >
  )
}
