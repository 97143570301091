import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import { getToken } from "@/storage/LocalParamers";
import { loadLocales } from "@/utils/HandlerLocales";
import { Image, Tooltip } from 'antd';
import { putJumpLoginPage, putSelectGoods } from '@/storage/SessionParamers'
const errorPhoto = require('@/images/errorPhoto.png')
class DetailsTopTabs extends Component {
  constructor(props) {
    super(props);
    loadLocales('products')

  }
  render() {
    const { selectDetail, listParam, listRecmds, symbol } = this.props;
    return (
      <section className="tab-product m-0">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <Tabs className="tab-content nav-material">
              <TabList className="nav nav-tabs nav-material">
                <Tab className="nav-item">
                  <span className="nav-link active">
                    <i className="icofont icofont-ui-home"></i>{intl.get("MatchingRecommendation")}
                  </span>
                  <div className="material-border"></div>
                </Tab>
                <Tab className="nav-item">
                  <span className="nav-link active">
                    <i className="icofont icofont-ui-home"></i>{intl.get("ProductParameters")}
                  </span>
                  <div className="material-border"></div>
                </Tab>
                <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-man-in-glasses"></i>{intl.get("ProductDescription")}
                  </span>
                  <div className="material-border"></div>
                </Tab>
              </TabList>
              <TabPanel className="tab-pane fade mt-4 show active">
                <div className="listRecmds_style">
                  {listRecmds && listRecmds.length > 0 ? listRecmds.map(res =>
                    <div >
                      <Image
                        width={110}
                        height={110}
                        src={res.mainPicture}
                        onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
                      />
                      <div className="cursor_hand" onClick={() => {
                        putSelectGoods(res.goodsCode);
                        this.props.history.push(`${process.env.PUBLIC_URL}/no-sidebar/product/${res.keyId}`)
                      }}>
                        <div className="recmds_prod_title">
                          <Tooltip title={res.goodsName}>
                            {res.goodsName}
                          </Tooltip>

                        </div>
                        {getToken() && getToken().indexOf(":5.1.1") == "-1" ?
                          <div className="recmds_prod_info">
                            <div className="red_text"> {symbol} {res.specialPrice ? res.specialPrice : res.price} </div>起
                          </div>
                          :
                          <div className="recmds_prod_info">
                            <div
                              onClick={() => { putJumpLoginPage(window.location.pathname); this.props.history.push(`${process.env.PUBLIC_URL}/pages/login`) }}
                              className="red_text">{intl.get('SignIn')}</div> <div className="text_grey">看价格</div>
                          </div>
                        }
                      </div>
                    </div>) : ""}
                </div>
              </TabPanel>
              <TabPanel className="tab-pane fade mt-4 show active">
                <table className="table table-striped mb-0">
                  <tbody>
                    {listParam
                      ? listParam.map(item => (
                        <tr>
                          <th>{item.paramName}:</th>
                          <td>{item.paramValue}</td>
                        </tr>
                      ))
                      : ""}
                  </tbody>
                </table>
              </TabPanel>
              <TabPanel>
                <div style={{ padding: "0 15px" }}>
                  <p

                    dangerouslySetInnerHTML={{
                      __html: selectDetail.introduce
                    }}
                  ></p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section >
    );
  }
}

export default DetailsTopTabs;
