//export const baseServerURL = "http://120.79.136.95"; //博士测试环境

export const baseServerURL = 'https://888.poksi.com.mo'; //博士正式环境

export const serverURL = baseServerURL + "/web";

const serverConfig = {
  baseURL: serverURL // 请求基础地址,可根据环境自定义
  // useTokenAuthorization: false // 是否开启 token 认证
};
export default serverConfig;
