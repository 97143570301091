import React, { Component } from "react";
import Slider from "react-slick";
import "../common/index.scss";
import { connect } from "react-redux";
import './no-sidebar.css'
// import custom Components
import RelatedProduct from "../common/related-product";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import { cartNumber, purchaseNow, addToWishlist } from "@/actions";
import ImageZoom from "./common/product/image-zoom";
import SmallImages from "./common/product/small-image";
import { getProductInfo, putProductInfo } from "@/storage/PageParamers";
import ProductRecommendation from '@/components/register/productRecommendation.js'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import {
  selectDetail,
  getInventory,
  selectRelevantGoods,
  addCar,
  getDetail,
  listCar,
  imgsList
} from "@/utils/user";
import {
  getToken,
  getCurCode
} from "@/storage/LocalParamers";
import { toast } from "react-toastify";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { getMainStoreKeyId } from "@/storage/SessionParamers";
const errorPhoto = require('@/images/errorPhoto.png')
class NoSideBar extends Component {
  constructor() {
    super();
    loadLocales('products')
    this.state = {
      nav1: null,
      nav2: null,
      propValuList: [],
      refresh: false,
      selectProductsIMG: []
    };
  }
  onRef = (ref) => {
    this.child = ref;
  }
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });

    const pathname = this.props.location.pathname

    const keyid = pathname.substring(pathname.lastIndexOf("\/") + 1, pathname.length)
    this.selectDetail(keyid);
    this.selectRelevantGoods(keyid);
  }

  selectDetail(keyId) {
    const { item } = this.props;
    let params = {
      goodsKeyId: !keyId ? item.keyId : keyId,
      mainStoreKeyId: getMainStoreKeyId()
    };
    selectDetail(params).then(res => {
      let newList = res.propValuList
      for (const propValuListValKey in res.propValuList) {
        for (const keys in res.propGoodsKeyIdList) {
          for (let val of newList[propValuListValKey]) {
            val.canChoose = true
            if (keys.includes(val.keyId)) {
              val.relatedProduct = true
            }
            if (!val.relatedProduct) {
              val.relatedProduct = false
            }
          }
        }
      }
      this.setState(
        {
          selectDetail: res,
          propValuList: newList
        },
        () => this.forceUpdate()
      );
      this.getInventory(res);
    });
  }

  updateClickSelectCategory(val) {
    this.setState({ propValuList: val })
  }

  getInventory(res) {
    let properties = res.propGoodsKeyIdList;
    let listProperties = [];
    for (let key in properties) {
      listProperties.push(key);
    }
    let params = {
      produceKeyId: res.relationGoodKeyId,
      mainStoreKeyId: getMainStoreKeyId(),
      properties: listProperties.length == 0 ? res.properties : listProperties
    };
    getInventory(params).then(res => {
      if (res.responseCode == "00") {
        this.setState({
          inventoryData: res.data
          // stock: res.totalQty
        }, () => {
          this.child.inventoryDataUpdata()
        });
      }
    });
  }

  getDetail = (value) => {
    const { selectDetail } = this.state;
    let params = {
      skuKeyId: value,
      // skuKeyId: selectDetail.propGoodsKeyIdList[value.keyId],
      mainStoreKeyId: getMainStoreKeyId()
    };
    let params2 = {
      produceKeyId: selectDetail.relationGoodKeyId
    };

    getDetail(params).then(res => {
      if (res) {
        res.propGoodsKeyIdList = selectDetail.propGoodsKeyIdList;
        this.getInventory(res);
        this.setState({ selectProductsData: res });
      }
    });
    imgsList(params2).then(res => {
      if (res && res.length > 0) {
        this.setState({ selectProductsIMG: res });
      } else {
        this.setState({ selectProductsIMG: [] });
      }
    })

  };
  selectRelevantGoods(keyId) {
    const { item } = this.props;
    let params = {
      goodsKeyId: !keyId ? item.keyId : keyId,
      mainStoreKeyId: getMainStoreKeyId()
    };
    selectRelevantGoods(params).then(res => {
      putProductInfo(res.records);
      this.setState(
        {
          selectProductsData: null,
          listSelectRelevantGoods: res.records
        },
        () => this.forceUpdate()
      );
    });
  }

  handleChildEvent = ref => {
    // 将子组件的实例存到 this.childRef 中, 这样整个父组件就能拿到
    this.childRef = ref;
  };
  //按钮事件处理
  handleClick = keyId => {
    this.selectDetail(keyId);
    this.selectRelevantGoods(keyId);
  };

  addCar = (quantity, relationGoodKeyId) => {
    const { item } = this.props;
    const { selectProductsData } = this.state
    let params = {
      outMainStoreKeyId: getMainStoreKeyId(),
      ownerKeyId: item.ownerKeyId,
      quantity: quantity,
      relationGoodKeyId: selectProductsData.relationGoodKeyId,
      skuKeyId: selectProductsData.keyId
    };
    addCar(params).then(res => {
      if (res.responseCode == "00") {
        this.listCars()
        toast.success(`${res.responseMessage}`);
      } else {
        toast.error(`${res.responseMessage}`);
      }
    });
  };
  listCars() {
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList) {
          if (res.data.shopCarVOList && res.data.shopCarVOList.length > 0) {
            let shopCarNum = 0
            res.data.shopCarVOList.map(res2 => res2.quantity ? shopCarNum += res2.quantity : "")
            this.props.cartNumber(shopCarNum)
          }
        }

      }
    });
  }
  nextPage() {
    const { totalPages, page } = this.state
    let newPage = page
    if (totalPages > newPage) {
      this.setState({ page: newPage + 1 }, () => this.refreshProductList());
    } else {
      this.setState({ page: 1 }, () => this.refreshProductList());
    }

  }
  previousPage() {
    const { totalPages, page } = this.state
    let newPage = page
    if (newPage == 1) {
      this.setState({ page: totalPages }, () => this.refreshProductList());
    } else {
      this.setState({ page: newPage - 1 }, () => this.refreshProductList());
    }
  }
  refreshProductList() {
    const sidebarPage = !this.props.sidebarPage ? 3 : 5;
    const list = getProductInfo()
    const { productList, page } = this.state
    let newProductList = []
    let nub = 0
    for (const key in list) {
      if (nub < (sidebarPage * page)) {
        newProductList.push(key)
        nub++
      }
    }
    newProductList = newProductList.filter(item1 => !productList.some(item2 => item2 === item1))
    this.setState({ productList: newProductList });
  }
  newest = ref => {
    this.newestRef = ref;
  };
  offers = ref => {
    this.offersRef = ref;
  };

  render() {
    const {
      symbol,
      item,
      addToCart,
      purchaseNow,
      addToWishlist
    } = this.props;
    var products = {
      fade: true
    };
    const {
      propValuList,
      inventoryData,
      selectDetail,
      listSelectRelevantGoods,
      selectProductsData,
      selectProductsIMG
    } = this.state;
    var productsnav = {
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      draggable: true,
      focusOnSelect: true
    };
    return (
      <div>
        <Breadcrumb title={intl.get('productCenter') + ' / ' + intl.get("ProductDetails")} />
        {/*Section Start*/}
        <div style={{ height: "40px" }}></div>
        {item ? (
          <section>
            <div className="collection-wrapper">
              <div className="container">
                <div className="sidebar_layout row">
                  <div className="row">
                    <div >
                      <Slider
                        {...products}
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        className="product-slick"
                        style={{ width: "380px" }}
                      >
                        <ImageZoom
                          image={
                            !selectProductsData
                              ? item.mainPicture
                              : selectProductsData.mainPicture
                          }
                        />

                      </Slider>

                      {/* <div className="picture_small">
                        {selectProductsIMG.map(item =>
                          <div><img onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }} /></div>
                        )}

                      </div> */}
                    </div>
                    {/* <SmallImages item={item} settings={productsnav} navOne={this.state.nav1} /> */}
                    <DetailsWithPrice
                      onRef={this.onRef}
                      selectDetail={!selectDetail ? [] : selectDetail}
                      symbol={symbol}
                      item={!selectProductsData ? item : selectProductsData}
                      navOne={this.state.nav1}
                      getDetail={this.getDetail}
                      addToCartClicked={this.addCar}
                      BuynowClicked={purchaseNow}
                      addToWishlistClicked={addToWishlist}
                      inventoryData={inventoryData}
                      history={this.props.history}
                      updateClickSelectCategory={this.updateClickSelectCategory}
                    />
                  </div>
                  <div style={{ width: "260px" }}>
                    <div className='sidebar_product_recommendation_title'>
                      {intl.get("discountedProductRecommendations")} <div>
                        <LeftOutlined
                          className={getProductInfo().length > 3 ? "" : "outlined_style"}
                          style={{ marginRight: "10px", cursor: getProductInfo().length > 3 ? "" : "not-allowed" }}
                          onClick={() => getProductInfo().length > 3 ? this.offersRef.previousPage() : ""} />
                        <RightOutlined
                          className={getProductInfo().length > 3 ? "" : "outlined_style"}
                          style={{ cursor: getProductInfo().length > 3 ? "" : "not-allowed" }}
                          onClick={() => getProductInfo().length > 3 ? this.offersRef.nextPage() : ""} />
                      </div>
                    </div>
                    <ProductRecommendation history={this.props.history} sidebarPage="true" recommendation={this.offers}></ProductRecommendation>
                  </div>
                </div>

              </div>
            </div>
          </section>
        ) : (
          ""
        )
        }
        {/*Section End*/}
        <section className="tab-product m-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-12">
                <DetailsTopTabs
                  selectDetail={!selectDetail ? [] : selectDetail}
                  inventoryData={!inventoryData ? [] : inventoryData}
                />
              </div>
            </div>
          </div>
        </section>


      </div >
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: getProductInfo().find(el => el.keyId == productId),
    symbol: getCurCode()
  };
};

export default connect(mapStateToProps, {
  cartNumber,
  purchaseNow,
  addToWishlist
})(NoSideBar);
