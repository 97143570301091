import React, { Component } from 'react';

import Breadcrumb from "../common/breadcrumb";
import intl from "react-intl-universal";

import { listAboutUsImage, } from "@/utils/user";
import "./login.css"
class aboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  componentDidMount() {
    listAboutUsImage().then(res => {
      if (res && res.data.length > 0) {
        this.setState({ data: res.data[0].imageUrl })
      }
    })
  }
  render() {
    const { data } = this.state;

    return (
      <div>
        <Breadcrumb title={intl.get("aboutUs")} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container">
            <img className='about-us' src={data} alt="" />
          </div>
        </section>

      </div>
    )
  }
}

export default aboutUs